export const NodeType = {
    AREA: "area",
    MEDIASPOT: "mediaspot",
    MODAC: "modac",
    EDAC: "edac",
    RADIAMETER: "radiameter",
    PROBE: "probe",
    BUS: "bus"
}


export const getNodeTypeFromString = (typeString) => {
    if(typeString === undefined){
        return NodeType.AREA
    }
    switch (typeString.toLowerCase()){
        case NodeType.MEDIASPOT: return NodeType.MEDIASPOT
        case NodeType.MODAC: return NodeType.MODAC
        case NodeType.EDAC: return NodeType.EDAC
        case NodeType.RADIAMETER: return NodeType.RADIAMETER
        case NodeType.PROBE: return NodeType.PROBE
        case NodeType.BUS: return NodeType.BUS
        default: return NodeType.AREA
    }
}
