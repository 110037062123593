import React, {Component} from "react"
import classes from "./Inventory.module.css"
import InventoryDevice from "./device/InventoryDevice"
// import { Multiselect } from 'multiselect-react-dropdown';
import {
    AssociatedDevicesParameter,
    getInventory, LogtypeParameter,
    StatusDevicesParameter
} from "../../requests/FactoriesRequests";
import {NodeType} from "../admin_areas/NodeType";
import "@progress/kendo-theme-material/dist/blue-pink-light.css"
import Loader from "react-loader-spinner";
import {getClientUUID} from "../../helpers/AuthHelper";
import {getI18n, Trans} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";

class Inventory extends Component {

    state = {
        devices: [],

        loadingInProgress: false,

        selectedTypesValue: [NodeType.MEDIASPOT, NodeType.MODAC, NodeType.PROBE, NodeType.RADIAMETER],
        serial: "",
        reference: "",
        filterAssociation: AssociatedDevicesParameter.ALL,

        startDeliveredDate: undefined,
        endDeliveredDate: undefined,

        startInstallationDate: undefined,
        endInstallationDate: undefined,

        statuses: [StatusDevicesParameter.ACTIVE],
        logtypes: [LogtypeParameter.REALTIME, LogtypeParameter.OFFLINE],

        selectedDevice: undefined
    }

    async componentDidMount() {
        await this.getDevices();
    }

    getDevices = async(event) => {
        if(event !== undefined){
            event.preventDefault()
        }
        await this.setState({loadingInProgress: true})

        const response = await getInventory(this.state.serial,
            this.state.reference,
            this.state.filterAssociation,
            this.state.statuses,
            this.state.selectedTypesValue,
            this.state.logtypes,
            getClientUUID())


        if(response.error !== undefined){
            alert(getI18n().t("AnErrorOccurred"))
            this.setState({loadingInProgress: false})
            return
        }
        let devices = []
        if(response[NodeType.MEDIASPOT] !== undefined){
            devices = devices.concat(response[NodeType.MEDIASPOT].Items)
        }
        if(response[NodeType.MODAC] !== undefined){
            devices = devices.concat(response[NodeType.MODAC].Items)
        }
        if(response[NodeType.RADIAMETER] !== undefined){
            devices = devices.concat(response[NodeType.RADIAMETER].Items)
        }
        if(response[NodeType.PROBE] !== undefined){
            devices = devices.concat(response[NodeType.PROBE].Items)
        }
        this.setState({devices: devices, loadingInProgress: false})

    }

    onDetailsClick = (type, id) => {

    }


    onStatusFilterChanged = (type) => {
        let statuses = [...this.state.statuses]
        if(this.state.statuses.includes(type)){
            const indexToDelete = statuses.indexOf(type)
            if(indexToDelete > -1){
                statuses.splice(indexToDelete, 1)
            }
        }else {
            statuses.push(type)
        }
        this.setState({statuses: statuses})
    }

    onLogTypeFilterChanged = (type) => {
        let logtypes = [...this.state.logtypes]
        if(this.state.logtypes.includes(type)){
            const indexToDelete = logtypes.indexOf(type)
            if(indexToDelete > -1){
                logtypes.splice(indexToDelete, 1)
            }
        }else {
            logtypes.push(type)
        }
        this.setState({logtypes: logtypes})
    }

    onSerialChanged = (e) => {
        const serialValue = e.target.value
        this.setState({serial: serialValue})
    }

    onReferenceChanged = (e) => {
        const referenceValue = e.target.value
        this.setState({reference: referenceValue})
    }

    onAssociatedFilterChanged = (associatedValueToUpdate) => {
        this.setState({filterAssociation: associatedValueToUpdate})
    }

    onShowDeviceDetails = (device) => {
        alert("show details")
    }

    onTypeChanged = (type) => {
        let types = [...this.state.selectedTypesValue]
        if(this.state.selectedTypesValue.includes(type)){
            const indexToDelete = types.indexOf(type)
            if(indexToDelete > -1){
                types.splice(indexToDelete, 1)
            }
        }else {
            types.push(type)
        }
        this.setState({selectedTypesValue: types})
    }

    render() {
        return (
            <div className={classes.InventoryRootContainer}>
                <div className={classes.DeviceSearchContainer}>
                    <form onSubmit={this.getDevices}>
                        <div style={{display: "flex", flexDirection: "column"}}>
                            <div className={classes.InventorySearchColumnContainer}>
                                <div className={classes.DeviceSearchInputContainer}>
                                    <label><Trans>Serial</Trans> : </label>
                                    <input onChange={this.onSerialChanged} value={this.state.serial} type="text"/>
                                </div>

                                <div className={classes.DeviceSearchInputContainer}>
                                    <label><Trans>Reference</Trans> : </label>
                                    <input onChange={this.onReferenceChanged} value={this.state.reference} type="text"/>
                                </div>

                            </div>

                            <div className={classes.DeviceSearchInputContainer}>
                                <label><Trans>Associated</Trans> : </label>
                                <div>
                                    <input id={"associatedTrue"} onChange={() => this.onAssociatedFilterChanged(AssociatedDevicesParameter.ASSOCIATED) } type="radio" name="associated" value={AssociatedDevicesParameter.ASSOCIATED} checked={this.state.filterAssociation === AssociatedDevicesParameter.ASSOCIATED}/>
                                    <label htmlFor={"associatedTrue"}> <Trans>Yes</Trans></label>
                                </div>
                                <div>
                                    <input id={"associatedFalse"} onChange={() => this.onAssociatedFilterChanged(AssociatedDevicesParameter.NOT_ASSOCIATED) } type="radio" name="associated" value={AssociatedDevicesParameter.NOT_ASSOCIATED} checked={this.state.filterAssociation === AssociatedDevicesParameter.NOT_ASSOCIATED}/>
                                    <label htmlFor={"associatedFalse"}> <Trans>No</Trans></label>
                                </div>
                                <div>
                                    <input id={"associatedAll"} onChange={() => this.onAssociatedFilterChanged(AssociatedDevicesParameter.ALL) } type="radio" name="associated" value={AssociatedDevicesParameter.ALL} checked={this.state.filterAssociation === AssociatedDevicesParameter.ALL}/>
                                    <label htmlFor={"associatedAll"}> <Trans>All</Trans></label>
                                </div>
                            </div>

                            <div className={classes.DeviceSearchInputContainer}>
                                <label><Trans>Status</Trans> : </label>
                                <div>
                                    <input id={"statusActive"} onChange={() => this.onStatusFilterChanged(StatusDevicesParameter.ACTIVE) } type="checkbox" name="status" value={StatusDevicesParameter.ACTIVE} checked={this.state.statuses.includes(StatusDevicesParameter.ACTIVE)}/>
                                    <label htmlFor={"statusActive"}> <Trans>Active</Trans></label>
                                </div>
                                <div>
                                    <input id={"statusInactive"} onChange={() => this.onStatusFilterChanged(StatusDevicesParameter.INACTIVE) } type="checkbox" name="status" value={StatusDevicesParameter.INACTIVE} checked={this.state.statuses.includes(StatusDevicesParameter.INACTIVE)}/>
                                    <label htmlFor={"statusInactive"}> <Trans>Inactive</Trans></label>
                                </div>
                                <div>
                                    <input id={"statusInStock"} onChange={() => this.onStatusFilterChanged(StatusDevicesParameter.IN_STOCK) } type="checkbox" name="status" value={StatusDevicesParameter.IN_STOCK} checked={this.state.statuses.includes(StatusDevicesParameter.IN_STOCK)}/>
                                    <label htmlFor={"statusInStock"}> <Trans>InStock</Trans></label>
                                </div>
                            </div>

                            <div className={classes.DeviceSearchInputContainer}>
                                <label><Trans>Log type</Trans> : </label>
                                <div>
                                    <input id={"logtypeOffline"} onChange={() => this.onLogTypeFilterChanged(LogtypeParameter.OFFLINE) } type="checkbox" name="logtype" value={LogtypeParameter.OFFLINE} checked={this.state.logtypes.includes(LogtypeParameter.OFFLINE)}/>
                                    <label htmlFor={"logtypeOffline"}> <Trans>Offline</Trans></label>
                                </div>
                                <div>
                                    <input id={"logtypeRealtime"} onChange={() => this.onLogTypeFilterChanged(LogtypeParameter.REALTIME) } type="checkbox" name="logtype" value={LogtypeParameter.REALTIME} checked={this.state.logtypes.includes(LogtypeParameter.REALTIME)}/>
                                    <label htmlFor={"logtypeRealtime"}> <Trans>Realtime</Trans></label>
                                </div>
                            </div>

                            <div className={classes.DeviceSearchInputContainer}>
                                <label><Trans>Type</Trans> : </label>
                                <div>
                                    <input id={"mediaspot"} onChange={(e) => this.onTypeChanged(NodeType.MEDIASPOT)} type="checkbox" name="devicetype" value={NodeType.MEDIASPOT} checked={this.state.selectedTypesValue.includes(NodeType.MEDIASPOT)}/>
                                    <label htmlFor={"mediaspot"}> Mediaspot</label>
                                </div>
                                <div>
                                    <input id={"modac"} onChange={(e) => this.onTypeChanged(NodeType.MODAC)} type="checkbox" name="devicetype" value={NodeType.MODAC} checked={this.state.selectedTypesValue.includes(NodeType.MODAC)}/>
                                    <label htmlFor={"modac"}> Modac</label>
                                </div>
                                <div>
                                    <input id={"radiameter"} onChange={(e) => this.onTypeChanged(NodeType.RADIAMETER)} type="checkbox" name="devicetype" value={NodeType.RADIAMETER} checked={this.state.selectedTypesValue.includes(NodeType.RADIAMETER)}/>
                                    <label htmlFor={"radiameter"}> <Trans>Radiameter</Trans></label>
                                </div>
                                <div>
                                    <input id={"probe"} onChange={(e) => this.onTypeChanged(NodeType.PROBE)} type="checkbox" name="devicetype" value={NodeType.PROBE} checked={this.state.selectedTypesValue.includes(NodeType.PROBE)}/>
                                    <label htmlFor={"probe"}> <Trans>Probe</Trans></label>
                                </div>
                            </div>

                        </div>
                        <div style={{display: "flex", flexDirection: "column"}}>
                            {
                                this.state.searchingRequestInProgress
                                    ? <Loader type={"Oval"} width={25} height={25} color={"#0095ff"}/>
                                    :<button className={classes.DeviceSearchValidateButton} type={"submit"}><FontAwesomeIcon icon={faSearch}/> <Trans>Search</Trans></button>}

                        </div>
                    </form>
                </div>




            <div className={classes.InventoryContainer}>
                <table className={classes.InventoryTable}>
                    <thead>
                    <tr>
                        <td><Trans>Type</Trans></td>
                        <td><Trans>SerialNumber</Trans></td>
                        <td><Trans>Reference</Trans></td>
                        <td><Trans>Associated</Trans></td>
                        <td><Trans>DeliveredDate</Trans></td>
                        <td><Trans>InstallationDate</Trans></td>
                        <td><Trans>Status</Trans></td>
                        <td><Trans>LogsType</Trans></td>
                        <td><Trans>More</Trans></td>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        this.state.loadingInProgress === false ? this.state.devices.map(device => {
                            return <InventoryDevice key={device.pk} onSelect={this.onShowDeviceDetails} device={device}/>
                        }): <tr>
                            <td colSpan={9} style={{border:"none"}}>
                                <Loader type="Oval" color="#4185F4" height={50} width={50}/>
                            </td>
                        </tr>

                    }
                    </tbody>
                </table>
            </div>

                </div>
        )
    }

}

export default Inventory;
