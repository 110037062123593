export const getDrivers = async() => {
    return [
        {
            id: 1,
            firstname: "Vincent",
            lastname: "AUVRAY",
            email: "vincent.auvray@fast-point.net",
            driverCardNumber: 822,
            globalNotation: 4.3,
            consumption: 120.1,
            comfort: {
                notation: 4.3,
                points: 300,
            },
            brake: {
                notation: 4.7,
                consumption: 120
            },
            speed: {
                notation: 3.1,
                consumption: 1.4
            },
            onStopEngine: {
                durationInMin: 149,
                consumption: 4.2,
                notation: 3.4
            },
            freewheel: {
                percentage: 21.43,
                notation: 1.8,
            },
            distance: 240.93,
            duration: 124
        },
        {
            id: 2,
            firstname: "Mathieu",
            lastname: "EDET",
            email: "mathieu.edet@fast-point.net",
            driverCardNumber: 823,
            globalNotation: 4.1,
            consumption: 159.8,
            comfort: {
                notation: 3.0,
                points: 228,
            },
            brake: {
                notation: 4.8,
                consumption: 111
            },
            speed: {
                notation: 3.0,
                consumption: 1.7
            },
            onStopEngine: {
                durationInMin: 94,
                consumption: 2.1,
                notation: 5.0
            },
            freewheel: {
                percentage: 22.98,
                notation: 2.6,
            },
            distance: 261.56,
            duration: 147
        },
        {
            id: 3,
            firstname: "Brice",
            lastname: "MIRAMONT",
            email: "brice.miramont@fast-point.net",
            driverCardNumber: 824,
            globalNotation: 4.4,
            consumption: 129.8,
            comfort: {
                notation: 2.1,
                points: 125,
            },
            brake: {
                notation: 5.0,
                consumption: 80
            },
            speed: {
                notation: 5.0,
                consumption: 1.0
            },
            onStopEngine: {
                durationInMin: 110,
                consumption: 2.9,
                notation: 4.8
            },
            freewheel: {
                percentage: 23.11,
                notation: 2.7,
            },
            distance: 300.81,
            duration: 199
        },
        {
            id: 4,
            firstname: "Paul",
            lastname: "BACHELET",
            email: "paul.bachelet@fast-point.net",
            driverCardNumber: 825,
            globalNotation: 4.3,
            consumption: 133.2,
            comfort: {
                notation: 4.0,
                points: 300,
            },
            brake: {
                notation: 4.7,
                consumption: 120
            },
            speed: {
                notation: 3.1,
                consumption: 1.4
            },
            onStopEngine: {
                durationInMin: 149,
                consumption: 4.2,
                notation: 3.4
            },
            freewheel: {
                percentage: 21.43,
                notation: 1.8,
            },
            distance: 240.93,
            duration: 110
        }
    ]
} 