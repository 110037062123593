import classes from "../../Dashboard.module.css"
import driversClasses from "./DriversList.module.css"
import React, {Component} from "react" 
import { Trans } from "react-i18next"
import { getDrivers } from "../../../requests/GetDrivers"
import { Link, withRouter } from "react-router-dom"

class DriversList extends Component {

    state = {
        drivers: undefined
    }

    async componentDidMount(){
        const driversResponse = await getDrivers()
        this.setState({drivers: driversResponse})
    }

    render(){
        if(!this.state.drivers){
            return <label>Chargement...</label>
        }

        return (
            <div className={classes.ChartContainer} style={{overflow: "auto", width: "100%", marginTop: "50px"}}>
                
                <label><Trans>Liste des conducteurs</Trans></label>
                
                <div className={classes.DashboardDevicesTitleSeparator}/>

                <div className={classes.DashboardDevicesGroupContent}>
                
                    <table className={driversClasses.DriverListTable}>
                        <thead>
                            <tr>
                                <th>Note globale</th>
                                <th>Nom</th>
                                <th>Durée de conduite</th>
                                <th>Distance</th>
                                <th>Confort</th>
                                <th>Accélération</th>
                                <th>Freinage</th>
                                <th>Vitesse</th>
                                <th>Moteur tournant à l'arrêt</th>
                                <th>DC (MJ/100km)</th>
                                <th>Roue libre (%)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.drivers.map(driver => {
                                    return (
                                        <tr onClick={() => this.props.history.push("/dashboard/eco-driving/drivers/" + driver.id)}>
                                            <td>{driver.globalNotation}</td>
                                            <td>{driver.lastname} {driver.firstname}</td>
                                            <td>{driver.duration} min</td>
                                            <td>{driver.distance} km</td>
                                            <td>{driver.comfort.notation}</td>
                                            <td>{driver.comfort.notation}</td>
                                            <td>{driver.brake.notation}</td>
                                            <td>{driver.speed.notation}</td>
                                            <td>{driver.onStopEngine.notation}</td>
                                            <td>{driver.consumption}</td>
                                            <td>{driver.freewheel.percentage}</td>                                                
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default withRouter(DriversList)