import axios from "./axios";
import {config} from "../config";

export const AssociatedDevicesParameter = {
    NOT_ASSOCIATED: 0,
    ASSOCIATED: 1,
    ALL: 2
}

export const StatusDevicesParameter = {
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE",
    IN_STOCK: "IN_STOCK",
    ARCHIVE: "ARCHIVE"
}

export const LogtypeParameter = {
    REALTIME: "REALTIME",
    OFFLINE: "OFFLINE"
}

const postRequest = async(url, body) => {
    try{
        console.info(url)
        console.info(body)
        const response = await axios.post(url, body)

        if(response.data === undefined){
            return {
                error: `undefined data response for request on ${url}`
            }
        }
        return response.data;
    }catch(err){
        return {
            error: err
        }
    }
}

/***
 * Get all organization nodes details
 * @returns {Promise<any|{error: *}>} promise with error key or organization nodes object
 */
export const getOrganization = async() => {
    //return await postRequest(config.API_URL + "/getorga")
    return {
        "46C0B0C4-1D34-4E19-A509-52B1B9280E86": {
            "type": "client",
            "val": "Entreprise",
            "isVisible": true,
            "isStore": false,
            "hasMediaspot": false,
            "isAllowed": true,
            "A5946E29-8EB7-4E54-956C-80C171269304": {
                "type": "site",
                "val": "Ligne 8",
                "isVisible": true,
                "isStore": false,
                "hasMediaspot": false,
                "isAllowed": true,
                "1BDA01A2-58C2-4351-A273-24EC41A267DF": {
                    "type": "bus",
                    "val": "801",
                    "isVisible": true,
                    "isStore": false,
                    "hasMediaspot": true,
                    "isAllowed": true,
                },
                "B9DD5002-501D-4DF7-BA93-4C742D72E277": {
                    "type": "bus",
                    "val": "802",
                    "isVisible": true,
                    "isStore": false,
                    "hasMediaspot": false,
                    "isAllowed": true,
                },
                "C9DD5002-501D-4DF7-BA93-4C742D72E277": {
                    "type": "bus",
                    "val": "694",
                    "isVisible": true,
                    "isStore": false,
                    "hasMediaspot": true,
                    "isAllowed": true,
                },
                "D9DD5002-501D-4DF7-BA93-4C742D72E277": {
                    "type": "bus",
                    "val": "695",
                    "isVisible": true,
                    "isStore": false,
                    "hasMediaspot": false,
                    "isAllowed": true,
                },
                "F9DD5002-501D-4DF7-BA93-4C742D72E277": {
                    "type": "bus",
                    "val": "901",
                    "isVisible": true,
                    "isStore": false,
                    "hasMediaspot": false,
                    "isAllowed": true,
                }
            },
            "orgTypeUUID": "FD0CF4EB-52B4-46BE-8949-51D0FA38527D"
        }
    }
}

/***
 * Get all organization types used to get node type information (picto, label, level...)
 * @param orgaTypeUUID organization type unique identifier used to bind nodeType parameter to its type.
 * Retrieved from root node of getorga endpoint
 * @returns {Promise<any|{error: *}>} promise with error key or an array of modacs
 */
export const getOrgaTypes = async(orgaTypeUUID) => {
    return {
        "org": {
            "icon": "fa-user",
            "nodeType": "client",
            "level": 0,
            "child": {
                "icon": "fa-industry",
                "nodeType": "site",
                "level": 1,
                "child": {
                    "icon": "fa-city",
                    "nodeType": "city",
                    "level": 2,
                    "desc": "city",
                    // "child": {
                    //     "icon": "fa-bus",
                    //     "nodeType": "bus",
                    //     "level": 3,
                    //     "desc": "bus"
                    // }
                },
                "desc": "ckey"
            },
            "desc": "root node"
        },
        "sk": "orgtype",
        "gsi1sk": "client|46C0B0C4-1D34-4E19-A509-52B1B9280E86",
        "pk": "orgtype|FD0CF4EB-52B4-46BE-8949-51D0FA38527D",
        "libelle": "default org"
    }
}

/***
 * Get all modacs
 * @returns {Promise<any|{error: *}>} promise with error key or an array of modacs
 */
export const getModacs = async() => {
    return await postRequest(config.API_URL + "/getmodacs")
}

/***
 * Get all mediaspots
 * @returns {Promise<any|{error: *}>} promise with error key or an array of mediaspots
 */
export const getMediaspots = async() => {
    return await postRequest(config.API_URL + "/getmediaspots")
}

/***
 * Get list of requested devices filtered with differents options in request
 * @param serial serial of device to filter (can be undefined to ignore)
 * @param reference reference of device to filter (can be undefined to ignore)
 * @param associated integer indicating if all devices have to be retrieved, or just associated, or just non-associated
 * @param types array of type string of types to get
 * (can be "NodeType.Mediaspot", "NodeType.Modac", "NodeType.Radiameter, NodeType.Probe"
 * @param clientUUID unique client identifier (retrieved from jwt token)
 * @returns {Promise<any|{error: *}>}
 */
export const getDevices = async(serial, reference, associated, types, clientUUID) => {
    return {
            mediaspot: {
                Count: 6,
                ScannedCount: 6,
                Items: [
                    {
                        buildVersion: "build-nspINTEGRATED-2020-03-27.175",
                        model: "D341010WYKH",
                        gsi1sk: "46C0B0C4-1D34-4E19-A509-52B1B9280E86|A5946E29-8EB7-4E54-956C-80C171269304|1BDA01A2-58C2-4351-A273-24EC41A267DF",
                        status: "ACTIVE",
                        brand: "Intel",
                        statusUpdatedOn: "2020-06-10T14:58:02+02:00",
                        driver: "EDET Mathieu",
                        lastIndex: 0,
                        lastWSContact: (Date.now() - 1000 * 60 * 5), // 5 min ago
                        searchRef: "olh-mediaspot-01",
                        reference: "MEDIASPOT-01",
                        memoryInGB: 4,
                        sk: "mediaspot|46C0B0C4-1D34-4E19-A509-52B1B9280E86",
                        device_type: "mediaspot",
                        pk: "mediaspot-GEWY450007VY|0",
                        HDDinGB: 256,
                        log_type: "REALTIME",
                        serial: "GEWY450007VY",
                        isDeployed: true,
                        type: "Nuc"
                    },
                    {
                        buildVersion: "build-nspINTEGRATED-2020-03-27.175",
                        model: "D341010WYKH",
                        gsi1sk: "46C0B0C4-1D34-4E19-A509-52B1B9280E86|A5946E29-8EB7-4E54-956C-80C171269304|C9DD5002-501D-4DF7-BA93-4C742D72E277",
                        status: "ACTIVE",
                        brand: "Intel",
                        statusUpdatedOn: "2020-06-10T14:58:02+02:00",
                        lastIndex: 0,
                        lastWSContact: (Date.now() - 1000 * 60 * 60 * 3), // 3h
                        searchRef: "olh-mediaspot-02",
                        reference: "MEDIASPOT-02",
                        memoryInGB: 4,
                        sk: "mediaspot|46C0B0C4-1D34-4E19-A509-52B1B9280E86",
                        device_type: "mediaspot",
                        pk: "mediaspot-GEWY477008AD|0",
                        HDDinGB: 256,
                        log_type: "REALTIME",
                        serial: "GEWY477008AD",
                        isDeployed: true,
                        type: "Nuc"
                    }
                ]
            },
        modac: {
            Count: 6,
            ScannedCount: 6,
            Items: [
                // {
                //     "gsi1sk": "46C0B0C4-1D34-4E19-A509-52B1B9280E86|A5946E29-8EB7-4E54-956C-80C171269304|1BDA01A2-58C2-4351-A273-24EC41A267DF|C3EDB868-41D1-45AB-98B6-6277ACCA098C",
                    
                //     "status": "ACTIVE",
                //     "statusUpdatedOn": "2020-01-01T12:00:00+02:00",
                //     "lastIndex": 0,
                //     "searchRef": "edac",
                //     "reference": "Balise Fastpoint 761",
                //     "macaddr": "30AEA4BBD7E0",
                //     "detectedMediaspotSerial": "GEWY450007VY",
                //     "last_received_event": "2020-10-14T16:53:25.013+02:00",
                //     "sk": "edac|46C0B0C4-1D34-4E19-A509-52B1B9280E86",
                //     "device_type": "edac",
                //     "pk": "edac-30AEA4BBD7E0|0",
                //     "log_type": "REALTIME",
                //     "serial": "30AEA4BBD7E0",
                //     "isDeployed": true,
                //     "parent_key": "mediaspot-GEWY450007VY|0"
                // },
            ]
        },
        radiameter: {
            Count: 6,
            ScannedCount: 6,
            Items: []
        },
        probe: {
            Count: 6,
            ScannedCount: 6,
            Items: []
        },

            NbDevices: 2
        }

}


/***
 * Create a new area node on organization with specific type, specific label on a specific node
 * @param label new node text label to describe this node
 * @param nodeType type of node (type is retrieved from getorgtype endpoint)
 * @param path node path where new node will be added as children
 * @param reserve boolean indicating if this new node
 * @param orgTypeUUID organization type unique identifier used to bind nodeType parameter to its type
 * @returns {Promise<any|{error: *}>}
 */
export const addNode = async(label, nodeType, path, reserve, orgTypeUUID) => {
    const body = JSON.stringify({
        label: label,
        type: nodeType,
        parentNode: path,
        store: reserve,
        orgtypeUUID: orgTypeUUID
    })

    return await postRequest(config.API_URL + "/addnode", body)
}

/***
 * Edit label or type of an area node
 * @param nodePath node path to edit ("xxxxxxxxxxxxx|yyyyyyyyyyyyyy|....)
 * @param label new node text label to describe this node
 * @param nodeType type of node (type is retrieved from getorgtype endpoint)
 * @param orgTypeUUID organization type unique identifier used to bind nodeType parameter to its type
 * @returns {Promise<any|{error: *}>} promise with error key or object response
 */
export const editNode = async(nodePath, label, nodeType, orgTypeUUID) => {
    const body = JSON.stringify({
        node: nodePath,
        label: label,
        type: nodeType,
        orgtypeUUID: orgTypeUUID
    })

    return await postRequest(config.API_URL + "/editnode", body)
}


/***
 * Associate device to another device
 * @param deviceId device primary key to associate
 * @param parentId device primary key of parent to associate device
 * @param clientUUID unique client identifier (retrieved from jwt token)
 * @returns {Promise<any|{error: *}>} promise with error key or object response
 */
export const associateDevice = async(deviceId, parentId, clientUUID) => {
    const body = JSON.stringify({
        device_pk: deviceId,
        parent_pk: parentId,
        clientUUID: clientUUID
    })

    return await postRequest(config.API_URL + "/associatedevice", body)
}

/***
 * Associate mediaspot to an area
 * @param mediaspotId primary key of mediaspot to associate
 * @param path node path to associate mediaspot
 * @param clientUUID unique client identifier (retrieved from jwt token)
 * @returns {Promise<any|{error: *}>} promise with error key or object response
 */
export const associateMediaspot = async(mediaspotId, path, clientUUID) => {
    const body = JSON.stringify({
        mediaspot_pk: mediaspotId,
        dest_node: path,
        clientUUID: clientUUID
    })

    return await postRequest(config.API_URL + "/associatemediaspot", body)
}

/***
 * Dissociate a device from its parent (node or device)
 * @param deviceId device primary key of device to dissociate
 * @param sourceNodePath node path of device to dissociate
 * @param targetNodePath node path of reserve to move device
 * @param clientUUID unique client identifier (retrieved from jwt token)
 * @returns {Promise<any|{error: *}>} returns promise with error key or object response
 */
export const dissociateDevice = async(deviceId, sourceNodePath, targetNodePath, clientUUID) => {
    const body = JSON.stringify({
        device_pk: deviceId,
        targetNode: targetNodePath,
        sourceNode: sourceNodePath,
        clientUUID: clientUUID
    })

    return await postRequest(config.API_URL + "/dissociatedevice", body)
}

/***
 * Delete a organization node
 * @param path node path to delete (this path should not contains child node or associated devices)
 * @param orgTypeUUID organization type unique identifier used to bind nodeType parameter to its type
 * @returns {Promise<any|{error: *}>} returns promise with error key or object response
 */
export const deleteNode = async(path, orgTypeUUID) => {
    const body = JSON.stringify({
        node: path,
        orgtypeUUID: orgTypeUUID
    })

    return await postRequest(config.API_URL + "/deletenode", body)
}

/***
 * Delete an inactive device
 * @param parentNodePath node path of device
 * @param deviceId device primary key of device to dissociate
 * @param clientUUID unique client identifier (retrieved from jwt token)
 * @returns {Promise<any|{error: *}>} returns promise with error key or object response
 */
export const deleteDevice = async(parentNodePath, deviceId, clientUUID) => {
    const body = JSON.stringify({
        parentNode: parentNodePath,
        device_pk: deviceId,
        clientUUID: clientUUID
    })

    return await postRequest(config.API_URL + "/deletedevice", body)
}

/***
 * Get list of requested devices filtered with differents options in request
 * @param serial serial of device to filter (can be undefined to ignore)
 * @param reference reference of device to filter (can be undefined to ignore)
 * @param associated integer indicating if all devices have to be retrieved, or just associated, or just non-associated
 * @param statuses array of String ("ACTIVE", "INACTIVE", "ARCHIVE", "IN_STOCK") representing device statuses
 * @param types array of type string of types to get
 * (can be "NodeType.Mediaspot", "NodeType.Modac", "NodeType.Radiameter, NodeType.Probe"
 * @param logTypes list of string representing logtypes to request (can be "OFFLINE", "REALTIME")
 * @param clientUUID unique client identifier (retrieved from jwt token)
 * @returns {Promise<any|{error: *}>}
 */
export const getInventory = async(serial, reference, associated, statuses, types, logTypes, clientUUID) => {
    const body = JSON.stringify({
        isDeployed: parseInt(associated),
        types: types,
        serial: serial === undefined ? "" : serial,
        reference: reference === undefined ? "" : reference,
        clientUUID: clientUUID,
        status: statuses,
        log_type: logTypes
    })
    return await postRequest(config.API_URL + "/getinventory", body)
}