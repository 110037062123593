import classes from "../../Dashboard.module.css"
import React, {Component} from "react" 
import { Trans } from "react-i18next"

class DriversList extends Component {

    state = {
        drivers: [
            {
                firstname: "Vincent",
                lastname: "AUVRAY",
                affectation: "Cherbourg",
                connectedTo: "-",
                driverCardUnloading: "-"
            },
            {
                firstname: "Paul",
                lastname: "BACHELET",
                affectation: "Cherbourg",
                connectedTo: "-",
                driverCardUnloading: "-"
            },
            {
                firstname: "Mathieu",
                lastname: "EDET",
                affectation: "Cherbourg",
                connectedTo: "-",
                driverCardUnloading: "-"
            },
            {
                firstname: "Brice",
                lastname: "MIRAMONT",
                affectation: "Cherbourg",
                connectedTo: "-",
                driverCardUnloading: "-"
            },
        ]
    }

    onConnectClick = (driver) => {
        alert("connect " + driver.firstname + " " + driver.lastname)
    }

    onDisconnectClick = (driver) => {
        alert("disconnect " + driver.firstname + " " + driver.lastname)
    }

    render(){
        return (
            <div className={classes.ChartContainer} style={{overflow: "auto", width: "100%", marginTop: "50px"}}>
                
                <label><Trans>Liste des conducteurs</Trans></label>
                
                <div className={classes.DashboardDevicesTitleSeparator}/>

                <div className={classes.DashboardDevicesGroupContent}>
                
                    <table>
                        <thead>
                            <tr>
                                <th>Nom</th>
                                <th>Affectation</th>
                                <th>Connecté à</th>
                                <th>Déchargement carte conducteur</th>
                                <th>Connexion</th>
                                <th>Déconnexion</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.drivers.map(driver => {
                                    return (
                                        <tr>
                                            <td>{driver.firstname}</td>
                                            <td>{driver.lastname}</td>
                                            <td>{driver.connectedTo}</td>
                                            <td>{driver.driverCardUnloading}</td>
                                            <td><button onClick={() => this.onConnectClick(driver)}>Connexion</button></td>
                                            <td><button onClick={() => this.onDisconnectClick(driver)}>Déconnexion</button></td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default DriversList