import React, {Component} from "react"
import PropTypes from "prop-types"
import {Link} from "react-router-dom"
import classes from "../../Dashboard.module.css"
import { Trans } from "react-i18next"
//import vehicleDetailsClasses from "./VehicleDetails.module.css"
import { getVehicles } from "../../../requests/GetVehicles"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faGasPump, faClock, faRulerHorizontal, faHistory, faWaveSquare} from "@fortawesome/free-solid-svg-icons";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import { greenBusIcon } from "../../../helpers/Markers"

class VehicleDetails extends Component {

    
    state = {
        vehicle: undefined,
        vehicleError: undefined
    }

    async componentDidMount(){
        await this.getVehicleInfos()
    }

    async componentDidUpdate(prevProps){
        if(this.props.id !== prevProps.id){
            await this.getVehicleInfos()
        }
    }

    getVehicleInfos = async() => {
        const vehicleId = this.props.id
        const vehiclesResponse = await getVehicles()

        if(vehiclesResponse.error){
            this.setState({
                vehicleError: vehiclesResponse.error
            })
            return
        }

        const vehicle = vehiclesResponse.data.find(vehicle => {
            return vehicle.identifier.toString() === vehicleId.toString()
        })

        if(vehicle){
            this.setState({
                vehicle: vehicle,
            })
        }
    }

    render(){
        if(this.state.vehicleError){
            return <label>Erreur lors du chargement de véhicule</label>
        }

        if(!this.state.vehicle){
            return <label>Chargement...</label>
        }
        return (

            <>
            {this.props.fromList ? 
                <div className={classes.DashboardTabSubMenu}>
                    <Link to="/dashboard/eco-driving/vehicles/"><label>← Liste des véhicules</label></Link>
                </div>
                :undefined}
            

                <div className={classes.ChartContainer} style={{overflow: "auto", width: "100%", marginTop: this.props.fromList === true ? "100px" : "0px"}}>
                    
                    <label>Détails du véhicule</label>
                    
                    <div className={classes.DashboardDevicesTitleSeparator}/>

                    <div className={classes.DashboardDevicesGroupContent} style={{display: "flex"}}>

                        <div style={{width: "100%", height: "700px"}}>

                            <div className={classes.DashboardDevicesGroupContent}>
                                <div className={classes.DashboardDevicesKeyValueContainer}>
                                    <label><Trans>ID</Trans> :</label>
                                    <label>{this.state.vehicle.identifier}</label>
                                </div>
                                
                                <div className={classes.DashboardDevicesKeyValueContainer}>
                                    <label><Trans>Immatriculation</Trans> :</label>
                                    <label>{this.state.vehicle.serial}</label>
                                </div>

                                <div className={classes.DashboardDevicesKeyValueContainer}>
                                    <label><FontAwesomeIcon icon={faRulerHorizontal}/>&nbsp;&nbsp;<Trans>Odomètre</Trans> :</label>
                                    <label>{this.state.vehicle.odometer} km</label>
                                </div>

                                <div className={classes.DashboardDevicesKeyValueContainer}>
                                    <label><FontAwesomeIcon icon={faClock}/>&nbsp;&nbsp;<Trans>LastInfoReceived</Trans> :</label>
                                    <label>{new Date(this.state.vehicle.lastContact).toLocaleString()}</label>
                                </div>

                                <div className={classes.DashboardDevicesKeyValueContainer}>
                                    <label><FontAwesomeIcon icon={faGasPump}/>&nbsp;&nbsp;<Trans>Consommation totale</Trans> :</label>
                                    <label>{this.state.vehicle.totalConsumption} L</label>
                                </div>

                                <div className={classes.DashboardDevicesKeyValueContainer}>
                                    <label><FontAwesomeIcon icon={faGasPump}/>&nbsp;&nbsp;<Trans>Consommation dynamique moyenne</Trans> :</label>
                                    <label>{this.state.vehicle.dynamicAverageConsumption} L/100km</label>
                                </div>

                                <div className={classes.DashboardDevicesKeyValueContainer}>
                                    <label><FontAwesomeIcon icon={faHistory}/>&nbsp;&nbsp;<Trans>Moteur tournant à l'arrêt</Trans> :</label>
                                    <label>{this.state.vehicle.onStopEngine} secondes</label>
                                </div>

                                <div className={classes.DashboardDevicesKeyValueContainer}>
                                    <label><FontAwesomeIcon icon={faWaveSquare}/>&nbsp;&nbsp;<Trans>Consommation moyenne</Trans> :</label>
                                    <label>{this.state.vehicle.averageConsumption} L</label>
                                </div>
                                
                            </div>


                            <MapContainer style={{width: "100%", height: "400px" }} center={[this.state.vehicle.lastPosition.lat, this.state.vehicle.lastPosition.lng]} zoom={12} scrollWheelZoom={false}>
                                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
                                {
                                    <Marker icon={greenBusIcon} position={[this.state.vehicle.lastPosition.lat, this.state.vehicle.lastPosition.lng]}>
                                        <Popup>
                                            <b>Bus {this.state.vehicle.identifier}</b>
                                            <br />
                                            <label>Dernier contact : {new Date(this.state.vehicle.lastContact).toLocaleString()}</label>
                                        </Popup>
                                    </Marker>
                                }
                                
                            </MapContainer>

                        </div>
                    </div>
                </div>


            </>
        )
    }
}

VehicleDetails.propTypes = {
    id: PropTypes.number.isRequired,
    fromList: PropTypes.bool
}

export default VehicleDetails