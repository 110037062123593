import i18n from '../i18n'

export const secToText = (sec) => {
    let sec_num = parseInt(sec, 10); // don't forget the second param
    let days   = Math.floor(sec_num / (3600 * 24));
    let hours   = Math.floor(sec_num % (3600*24) / 3600);

    const divisorForMinutes = sec_num % (60 * 60);
    let minutes = Math.floor((divisorForMinutes) / 60)

    const divisorForSeconds = divisorForMinutes % 60;
    let seconds = Math.ceil(divisorForSeconds)

    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    return (days>0 ? days +' ' + i18n.t("d") + " " : '') +hours+'h '+minutes+'min '+seconds+"sec";
}

export const secToTextWithoutUselessUnit = (sec) => {
    let sec_num = parseInt(sec, 10); // don't forget the second param
    let days   = Math.floor(sec_num / (3600 * 24));
    let hours   = Math.floor(sec_num % (3600*24) / 3600);

    const divisorForMinutes = sec_num % (60 * 60);
    let minutes = Math.floor((divisorForMinutes) / 60)

    const divisorForSeconds = divisorForMinutes % 60;
    let seconds = Math.ceil(divisorForSeconds)

    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    return (days>0 ? days +' ' + i18n.t("d") + " " : '') + (hours>0 ? hours+' h ' : '')+ (minutes>0 ? minutes+' min ' : '') +(seconds>0 ? seconds+' sec ' : '')
}

export const secToMin = (sec) => {
    let sec_num = parseInt(sec, 10); // don't forget the second param
    const divisorForMinutes = sec_num % (60 * 60);
    let minutes = Math.floor((divisorForMinutes) / 60)

    const divisorForSeconds = divisorForMinutes % 60;
    let seconds = Math.ceil(divisorForSeconds)

    if (seconds < 10) {seconds = "0"+seconds;}
    return (minutes === 0 ? "" : `${minutes} min `)+seconds+" sec";
}

export const isToday = (someDate) => {
    const today = new Date()
    return someDate.getDate() === today.getDate() &&
        someDate.getMonth() === today.getMonth() &&
        someDate.getFullYear() === today.getFullYear()
}

export const isValidTimestamp = (timestamp) => {
    const newTimestamp = new Date(timestamp).getTime();
    return isNumeric(newTimestamp);
}

function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

export const timeAgoInSeconds = (timestamp) => {
    return (Date.now() / 1000) - timestamp;
}