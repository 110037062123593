import React, {Component} from "react"
import Interval from "../../../requests/elastic_search/Interval"
import Graph, {GraphType} from "../../graphs/GraphComponent"
import classes from "../../Dashboard.module.css"
import { Trans } from "react-i18next"
import averageNotationClasses from "./EvolutionAverageNotation.module.css"
import DriversNotation from "../DriversNotation/DriversNotation"

class EvolutionAverageNotation extends Component {

    
    state = {
        notationType: "global"
    }

    onSelectNotationType = (event) => {
        console.log(event.target.value)
        this.setState({notationType: event.target.value})
    }

    render(){
        return (

                <div className={classes.ChartContainer} style={{overflow: "auto", width: "100%", height: "100%"}}>
                    
                    <label><Trans>Évolution de la notation globale des conducteurs</Trans></label>
                    
                    <div className={classes.DashboardDevicesTitleSeparator}/>

                    <div className={classes.DashboardDevicesGroupContent}>

                    <select className={averageNotationClasses.EvolutionAverageTypeSelector} onChange={this.onSelectNotationType}>
                        <option value="global">Note globale</option>
                        <option value="comfort">Confort</option>
                        <option value="brake">Freinage</option>
                        <option value="speed">Vitesse excessive</option>
                        <option value="engine_on_at_stop">Moteur tournant à l'arrêt</option>
                        <option value="freewheel">Roue libre</option>
                    </select>                

                    <div className={classes.ChartGraphContainer}>
                        <Graph
                            graphType={GraphType.NOTATION}
                            interval={Interval.DAY}
                            startDatePeriodTimestamp={Date.now() - 1000 * 60 * 60 * 24 * 14} // 15 last days
                            endDatePeriodTimestamp={Date.now()}/>
                    </div>


    <div style={{display: "flex"}}>
        <div className={classes.ChartContainer} style={{overflow: "auto", width: "49%", marginRight: "1%", border: "1px solid #D3F3CE"}}>
                        
            <label style={{background: "#D3F3CE"}}><Trans>Conducteurs les plus performants</Trans></label>
            
            <div className={classes.DashboardDevicesTitleSeparator}/>

            <div className={classes.DashboardDevicesGroupContent}>
            
                <DriversNotation type={this.state.notationType} reverse={false}/>
            </div>
        </div>    
                


        <div className={classes.ChartContainer} style={{overflow: "auto", width: "49%", marginLeft: "1%", border: "1px solid #ffb3b3"}}>
            
            <label style={{background: "#ffb3b3"}}><Trans>Conducteurs les moins performants</Trans></label>
                
            <div className={classes.DashboardDevicesTitleSeparator}/>

            <div className={classes.DashboardDevicesGroupContent}>
                
                <DriversNotation type={this.state.notationType} reverse={true}/>
            </div>
        </div>    
    </div>
</div>
</div>
    
                
        )
    }
}

export default EvolutionAverageNotation