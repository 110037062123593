import React, {Component} from "react"
// import classes from "./Provisionning.module.css"

class Provisionning extends Component {
    render(){
        return <label>Provisionning section</label>
    }
}

export default Provisionning
