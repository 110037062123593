import React, {Component} from "react"
import classes from "./Card.module.css";

import PropTypes from "prop-types"
import Loader from "react-loader-spinner";
import {getAlarmsPercent} from "../../requests/elastic_search/GetAlarmsPercent";
import {getControlsCount} from "../../requests/elastic_search/GetControlsCount";
import {getAverageControlsDuration} from "../../requests/elastic_search/GetControlsAverageDuration";
import {getAlarmsCount} from "../../requests/elastic_search/GetAlarmsCount";
import {getActiveModacsCount} from "../../requests/elastic_search/GetActiveModacsCount";
import {getAverageModacsAvailability} from "../../requests/elastic_search/GetAverageModacAvailabilityV2.js";
import {getSyncCount} from "../../requests/elastic_search/GetSyncCount";
import {getErrorPercentage} from "../../requests/elastic_search/GetErrorsPercentage";
import {getI18n, Trans} from "react-i18next"
import {Link} from "react-router-dom";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearchPlus} from "@fortawesome/free-solid-svg-icons";

export const CardProps = {
    CONTROLS_COUNT: {
        key: "CONTROLS_COUNT",
        title: getI18n().t("ControlsCount"),
        color: "#1F9BD9",
        unit: "",
        decimalsCount: 0
    },
    AVERAGE_CONTROLS_DURATION: {
        key: "AVERAGE_CONTROLS_DURATION",
        title: getI18n().t("AverageControlsDuration"),
        color: "#1F9BD9",
        unit: "sec",
        decimalsCount: 0
    },
    CONTROLS_WITH_ALARM_COUNT: {
        key: "CONTROLS_WITH_ALARM_COUNT",
        title: getI18n().t("AlarmsCount"),
        color: "#32BC76",
        unit: "",
        decimalsCount: 0
    },
    ALARM_PERCENT: {
        key: "ALARM_PERCENT",
        title: getI18n().t("AlarmsPercentage"),
        color: "#32BC76",
        unit: "%",
        decimalsCount: 2
    },
    ACTIVE_MODACS_COUNT: {
        key: "ACTIVE_MODACS_COUNT",
        title: getI18n().t("NumberOfActiveModacs"),
        color: "#1F9BD9",
        unit: "",
        decimalsCount: 0
    },
    AVERAGE_MODAC_AVAILABILITY: {
        key: "AVERAGE_MODAC_AVAILABILITY",
        title: getI18n().t("ModacAvailability"),
        color: "#32BC76",
        unit: "%",
        decimalsCount: 2
    },
    SYNC_COUNT: {
        key: "SYNC_COUNT",
        title: getI18n().t("SynchronizationsCount"),
        color: "#32BC76",
        unit: "",
        decimalsCount: 0
    },
    CLIENTS_ERROR_PERCENT: {
        key: "CLIENTS_ERROR_PERCENT",
        title: getI18n().t("HTTPErrors"),
        color: "#32BC76",
        unit: "%",
        decimalsCount: 2
    },
    ENGINE_OIL_TEMPERATURE: {
        key: "ENGINE_OIL_TEMPERATURE",
        title: getI18n().t("EngineOilTemperature"),
        color: "#32BC76",
        unit: "°C",
        decimalsCount: 0
    },
    COUNT_OF_OIL_TEMPERATURE_THRESOLD_EXCEEDING: {
        key: "COUNT_OF_OIL_TEMPERATURE_THRESOLD_EXCEEDING",
        title: getI18n().t("ThesoldExceedingCount"),
        color: "#bc7232",
        unit: "",
        decimalsCount: 0
    },
    COUNT_OF_ISSUES: {
        key: "COUNT_OF_ISSUES",
        title: getI18n().t("IssuesCount"),
        color: "#bc7232",
        unit: "",
        decimalsCount: 0
    },
    NB_DAYS_BEFORE_NEXT_CHECK: {
        key: "NB_DAYS_BEFORE_NEXT_CHECK",
        title: getI18n().t("NumberOfDaysBeforeNextCheck"),
        color: "#bc7232",
        unit: "",
        decimalsCount: 0
    }
}

class Card extends Component {


    state = {
        value: undefined,
        isLoading: true,
        error: undefined
    }



    async componentDidMount() {
        if(this.props.infoType !== undefined){
            await this.getValue()
        }
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.infoType !== undefined && (this.props.startDatePeriodTimestamp !== prevProps.startDatePeriodTimestamp || this.props.nodePath !== prevProps.nodePath || this.props.deviceSerial !== prevProps.deviceSerial || this.props.modacMacAddr !== prevProps.modacMacAddr)){
            await this.getValue()
        }
    }

    getValue = async() => {
        if(!this.state.isLoading){
            await this.setState({isLoading: true})
        }
        let response = undefined
        switch (this.props.infoType) {
            case CardProps.CONTROLS_COUNT: {
                response = await getControlsCount(this.props.startDatePeriodTimestamp, this.props.endDatePeriodTimestamp, this.props.modacMacAddr, this.props.deviceSerial, this.props.nodePath)
                break
            }
            case CardProps.AVERAGE_CONTROLS_DURATION: {
                response = await getAverageControlsDuration(this.props.startDatePeriodTimestamp, this.props.endDatePeriodTimestamp, this.props.modacMacAddr, this.props.deviceSerial, this.props.nodePath)
                break
            }
            case CardProps.CONTROLS_WITH_ALARM_COUNT: {
                response = await getAlarmsCount(this.props.startDatePeriodTimestamp, this.props.endDatePeriodTimestamp, this.props.modacMacAddr, this.props.deviceSerial, this.props.nodePath)
                break
            }
            case CardProps.ALARM_PERCENT: {
                response = await getAlarmsPercent(this.props.startDatePeriodTimestamp, this.props.endDatePeriodTimestamp, this.props.selectedClientKey, this.props.selectedMediaspotName, this.props.modacMacAddr, this.props.deviceSerial, this.props.nodePath)
                break
            }
            case CardProps.ACTIVE_MODACS_COUNT: {
                response = await getActiveModacsCount(this.props.startDatePeriodTimestamp, this.props.endDatePeriodTimestamp, this.props.deviceSerial, this.props.nodePath)
                break;
            }
            case CardProps.AVERAGE_MODAC_AVAILABILITY: {
                response = await getAverageModacsAvailability(this.props.startDatePeriodTimestamp, this.props.endDatePeriodTimestamp, this.props.modacMacAddr, this.props.deviceSerial, this.props.nodePath)
                break;
            }
            case CardProps.SYNC_COUNT: {
                response = await getSyncCount(this.props.startDatePeriodTimestamp, this.props.endDatePeriodTimestamp, this.props.deviceSerial, this.props.nodePath)
                break;
            }
            case CardProps.CLIENTS_ERROR_PERCENT: {
                response = await getErrorPercentage(this.props.startDatePeriodTimestamp, this.props.endDatePeriodTimestamp, this.props.deviceSerial,  this.props.nodePath)
                break;
            }
            case CardProps.ENGINE_OIL_TEMPERATURE: {
                response = { data: "83"}
                break;
            }
            case CardProps.COUNT_OF_OIL_TEMPERATURE_THRESOLD_EXCEEDING: {
                response = { data: "0"}
                break;
            }
            case CardProps.COUNT_OF_ISSUES: {
                response = { data: "3"}
                break;
            }
            case CardProps.NB_DAYS_BEFORE_NEXT_CHECK: {
                response = { data: "44"}
                break;
            }
            default: {
                response = undefined;
            }
        }
        if(response === undefined || response.data === undefined){
            this.setState({
                isLoading: false,
                error: response !== undefined ? response.error : new Error("Unknown error")
            })
            return
        }

        this.setState({
            error: undefined,
            isLoading: false,
            value: response.data
        })
    }

    render() {
        return (
            <div className={classes.Card} style={{backgroundColor: this.props.backgroundColor === undefined ? this.props.infoType.color : this.props.backgroundColor, ...this.props.style}} >
                {
                    <>
                        <label>{this.props.title === undefined ? this.props.infoType.title : this.props.title}</label>

                        {this.state.error !== undefined ? <label>-</label> :

                        this.state.isLoading && this.props.infoType !== undefined ? <Loader type="Oval" color="#FFFFFF" height={30} width={30}/>  :
                            <>
                                <label>
                                    {this.props.value === undefined ?
                                        (this.state.value) >= 0 ?`${this.state.value.toFixed(this.props.infoType.decimalsCount)} ${this.props.infoType.unit}` : "-" :
                                        this.props.value
                                    }
                                </label>
                                {this.props.detailsLink !== undefined  ?
                                    <label>
                                        <Link to={this.props.detailsLink} className={classes.CardDetailsButton}><FontAwesomeIcon icon={faSearchPlus}/> <Trans>Details</Trans></Link>
                                    </label>
                                    : undefined
                                }
                            </>
                        }
                    </>
                }

            </div>
        )
    }
}

Card.propTypes = {
    infoType: PropTypes.oneOf([CardProps.CONTROLS_COUNT, CardProps.AVERAGE_CONTROLS_DURATION, CardProps.CONTROLS_WITH_ALARM_COUNT, CardProps.ALARM_PERCENT, CardProps.CLIENTS_ERROR_PERCENT, CardProps.SYNC_COUNT, CardProps.AVERAGE_MODAC_AVAILABILITY, CardProps.ACTIVE_MODACS_COUNT]).isRequired,
    selectedClientKey: PropTypes.string,
    selectedMediaspotName: PropTypes.string,
    startDatePeriodTimestamp: PropTypes.number,
    endDatePeriodTimestamp: PropTypes.number,
    modacMacAddr: PropTypes.string,
    deviceSerial: PropTypes.string,

    title: PropTypes.string,
    value: PropTypes.string,
    backgroundColor: PropTypes.string,

    detailsLink: PropTypes.string,

    nodePath: PropTypes.string
}

export default Card
