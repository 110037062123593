export const getTravels = async (driverId) => {
    if (!driverId) {
        return {
            error: "missing driver identifier"
        }
    }
    return {
        data: [{
                vehicleId: "101",
                vehicleImmat: "AW-293-UB",
                globalNotation: 4.3,
                startTimestamp: 1613822317000,
                endTimestamp: 1613834117000,
                distance: 123000,
                consumption: 4.1,

                comfort: {
                    notation: 4.3,
                    points: 300,
                    issuesCount: 0,
                },
                brake: {
                    notation: 4.7,
                    consumption: 120,
                    issuesCount: 0,
                },
                speed: {
                    notation: 3.1,
                    consumption: 1.4,
                    issuesCount: 2,
                },
                onStopEngine: {
                    durationInMin: 150,
                    consumption: 4.2,
                    notation: 3.5,
                },
                freewheel: {
                    percentage: 21.43,
                    notation: 1.8,
                },
                travel: [
                    {
                      from_lat: "49.637788",
                      from_long: "-1.634066",
                      to_lat: "49.636051",
                      to_long: "-1.631899",
                    },
                    {
                      from_lat: "49.636051",
                      from_long: "-1.631899",
                      to_lat: "49.633965",
                      to_long: "-1.623250",
                    },
                    {
                        from_lat: "49.633965",
                        from_long: "-1.623250",
                        to_lat: "49.641170",
                        to_long: "-1.621256",
                    },
                    {
                        from_lat: "49.637788",
                        from_long: "-1.634066",
                        to_lat: "49.636051",
                        to_long: "-1.631899",
                      }
                  ]

            },
            {
                vehicleId: "102",
                vehicleImmat: "CA-833-PP",
                globalNotation: 4.3,
                startTimestamp: 1613822017000,
                endTimestamp: 1613833117000,
                distance: 93000,
                consumption: 4.1,

                comfort: {
                    notation: 4.3,
                    points: 300,
                    issuesCount: 0,
                },
                brake: {
                    notation: 4.1,
                    consumption: 130,
                    issuesCount: 1,
                },
                speed: {
                    notation: 3.1,
                    consumption: 1.4,
                    issuesCount: 2,
                },
                onStopEngine: {
                    durationInMin: 149,
                    consumption: 4.2,
                    notation: 4.4,
                },
                freewheel: {
                    percentage: 21.43,
                    notation: 1.8,
                },
                travel: [
                    {
                      from_lat: "49.637588",
                      from_long: "-1.633066",
                      to_lat: "49.636051",
                      to_long: "-1.631899",
                    },
                    {
                      from_lat: "49.636051",
                      from_long: "-1.631899",
                      to_lat: "49.633965",
                      to_long: "-1.623250",
                    },
                    {
                        from_lat: "49.633965",
                        from_long: "-1.623250",
                        to_lat: "49.641170",
                        to_long: "-1.621256",
                      }
                  ]
            }
        ]
    }
}