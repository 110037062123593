export const getDataset = (notations) => {
    
    const dataset = {
        backgroundColor: "rgba(248,226,60,0.31)",
        borderColor: "#F8B33C",
        label: `Note`,
        data: [],
        lineTension: 0.1,
    }

    let dates = []
    notations.forEach(it => {
        const date = new Date(it.date)
        dates.push(date)

        dataset.data.push(
            {
                x: date,
                y: it.notation
            }
        )
    })
        
    return {
        datasets: [dataset],
        dates: dates
    }
}
