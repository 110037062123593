import React, {Component} from "react"
import PropTypes from "prop-types"
import {Link} from "react-router-dom"
import classes from "../../Dashboard.module.css"
import { Trans } from "react-i18next"
import driverDetailsClasses from "./DriverDetails.module.css"
import { getDrivers } from "../../../requests/GetDrivers"
import DriverTravels from "./DriverTravels/DriverTravels"


class DriverDetails extends Component {

    
    state = {
        notationType: "global",
        driver: undefined,
        travels: undefined,
        travelsError: undefined,
        selectedTravel: undefined
    }

    async componentDidMount(){
        const driverId = this.props.id
        const drivers = await getDrivers()

        const driver = drivers.find(driver => {
            return driver.id.toString() === driverId.toString()
        })

        if(driver){
            this.setState({
                driver: driver,
            })
        }
    }

    onSelectNotationType = (event) => {
        console.log(event.target.value)
        this.setState({notationType: event.target.value})
    }

    render(){
        if(!this.state.driver){
            return <label>Chargement...</label>
        }
        return (

            <>
                <div className={classes.DashboardTabSubMenu}>
                    <Link to="/dashboard/eco-driving/drivers/"><label>← Retour</label></Link>
                </div>

                <div className={classes.ChartContainer} style={{overflow: "auto", width: "100%", height: "100%", marginTop: "100px"}}>
                    
                    <label>{this.state.driver.lastname} {this.state.driver.firstname}</label>
                    
                    <div className={classes.DashboardDevicesTitleSeparator}/>

                    <div className={classes.DashboardDevicesGroupContent} style={{display: "flex"}}>

                        <div style={{width: "40%", textAlign: 'center'}}>
                            {/* <p>Graph here</p> */}

                            <meter value="4" min="0" max="5" title="Note">
                            </meter>  
                            
                            <br />
                            
                            <select className={driverDetailsClasses.TypeSelector} onChange={this.onSelectNotationType}>
                                <option value="global">Note globale</option>
                                <option value="comfort">Confort</option>
                                <option value="brake">Freinage</option>
                                <option value="speed">Vitesse excessive</option>
                                <option value="engine_on_at_stop">Moteur tournant à l'arrêt</option>
                                <option value="freewheel">Roue libre</option>
                            </select>
                            <label>Note : <b>{this.state.driver.globalNotation} / 5</b></label>
                        </div>
                        

                        <div style={{width: "60%"}}>

                            <div className={classes.DashboardDevicesGroupContent}>
                                <div className={classes.DashboardDevicesKeyValueContainer}>
                                    <label><Trans>Prénom</Trans> :</label>
                                    <label>{this.state.driver.firstname}</label>
                                </div>
                                
                                <div className={classes.DashboardDevicesKeyValueContainer}>
                                    <label><Trans>Nom</Trans></label>
                                    <label>{this.state.driver.lastname}</label>
                                </div>
                                
                                <div className={classes.DashboardDevicesKeyValueContainer}>
                                    <label><Trans>Email</Trans></label>
                                    <label>{this.state.driver.email}</label>
                                </div>
                                
                                <div className={classes.DashboardDevicesKeyValueContainer}>
                                    <label><Trans>Numéro de carte conducteur</Trans></label>
                                    <label>{this.state.driver.driverCardNumber}</label>
                                </div>
                                
                                <div className={classes.DashboardDevicesKeyValueContainer}>
                                    <label><Trans>N° d'employé</Trans></label>
                                    <label>{this.state.driver.id}</label>
                                </div>
                                
                                <div className={classes.DashboardDevicesKeyValueContainer}>
                                    <label><Trans>Dernière connexion WEB</Trans></label>
                                    <label>-</label>
                                </div>
                                
                                <div className={classes.DashboardDevicesKeyValueContainer}>
                                    <label><Trans>Dernière connexion sur le véhicule</Trans></label>
                                    <label>-</label>
                                </div>
                                
                                <div className={classes.DashboardDevicesKeyValueContainer}>
                                    <label><Trans>Carte conducteur lue dernièrement le</Trans></label>
                                    <label>-</label>
                                </div>

                                <div className={classes.DashboardDevicesKeyValueContainer}>
                                    <label><Trans>Déchargement de la carte conducteur programmée à compter du</Trans></label>
                                    <label>-</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className={classes.ChartContainer} style={{overflow: "auto", width: "100%", height: "500px", marginTop: "30px"}}>
                    
                    <label>Trajets de {this.state.driver.lastname} {this.state.driver.firstname}</label>
                    
                    <div className={classes.DashboardDevicesTitleSeparator}/>

                    <div className={classes.DashboardDevicesGroupContent} style={{display: "flex", height: "95%"}}>
                        <DriverTravels driverId={this.state.driver.id}/>
                    </div>
                </div>
            </>
        )
    }
}

DriverDetails.propTypes = {
    id: PropTypes.number.isRequired
}

export default DriverDetails