export const getEvolutionOfNotation = () =>  {
    var now = new Date(Date.now());
    now.setHours(0, 0, 0, 0);

    let data = []
    for(let i = 0 ; i < 15 ; i++) {
        data.push({
            date: now - (1000 * 60 * 60 * 24 * i),
            notation: Math.floor(Math.random() * 5) + 1 
        })
    }
    return data
}