import classes from "../../Dashboard.module.css"
import React, {Component} from "react" 
import { Trans } from "react-i18next"
import { getVehicles } from "../../../requests/GetVehicles"
import { withRouter } from "react-router-dom"
import vehiclesClasses from "./VehiclesList.module.css"

class VehiclesList extends Component {

    state = {
        vehicles: undefined,
        vehiclesRequestError: undefined
    }

    async componentDidMount(){
        const vehiclesResponse = await getVehicles()
        if(vehiclesResponse.error){
            this.setState({
                vehiclesRequestError: vehiclesResponse.error,
                vehicles: undefined
            })
            return
        }

        this.setState({
            vehicles: vehiclesResponse.data
        })

    }

    render(){

        if(this.state.vehiclesRequestError){
            return <label>Erreur de chargement des bus</label>
        }
        if(!this.state.vehicles){
            return <label>Chargement...</label>
        }

        return (
            <div className={classes.ChartContainer} style={{overflow: "auto", width: "100%", marginTop: "50px"}}>
                
                <label><Trans>Liste des véhicules</Trans></label>
                
                <div className={classes.DashboardDevicesTitleSeparator}/>

                <div className={classes.DashboardDevicesGroupContent}>
                
                    <table className={vehiclesClasses.VehiclesListTable}>
                        <thead>
                            <tr>
                                <th>Numéro</th>
                                <th>Immatriculation</th>
                                <th>Odomètre</th>
                                <th>Dernière communication</th>
                                <th>Consommation totale</th>
                                <th>Consommation dynamique moyenne</th>
                                <th>Moteur tournant à l'arrêt</th>
                                <th>Consommation moyenne</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.vehicles.map(vehicle => {
                                    return (
                                        <tr onClick={() => this.props.history.push("/dashboard/eco-driving/vehicles/" + vehicle.identifier)}>
                                            <td>{vehicle.identifier}</td>
                                            <td>{vehicle.serial}</td>
                                            <td>{vehicle.odometer} km</td>
                                            <td>{new Date(vehicle.lastContact).toLocaleString()}</td>
                                            <td>{vehicle.totalConsumption}</td>
                                            <td>{vehicle.dynamicAverageConsumption} L/100km</td>
                                            <td>{vehicle.onStopEngine} sec</td>
                                            <td>{vehicle.averageConsumption} L</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default withRouter(VehiclesList)