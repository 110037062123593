import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
    .use(initReactI18next)
    .use(LanguageDetector).init({
    // we init with resources
    resources: {
        en: {
            translations: {
                NoNucleotDevicesAvailableOnThisArea: "No Nucleot devices available on this area",
                NoAccessToPerimeter: "No access to this area",

                // Date range picker
                SelectPeriod: "Select period",
                LastHours: "Last hours",
                Last24Hours: "Last 24 hours",
                Last48Hours: "Last 48 hours",
                Last7Days: "Last 7 days",
                LastMonth: "Last month",
                LastYear: "Last year",

                // Tabs
                Measures: "Measures",
                Events: "Events",
                Devices: "Devices",
                Back: "Back",
                Supervision: "Supervision",

                // Interval selector
                PerHour: "Per hour",
                PerDay: "Per day",
                d: 'd',

                // Cards
                ControlsCount: "Controls count",
                AverageControlsDuration: "Average controls duration",
                Alarms: "Alarms",
                AlarmsCount: "Alarms",
                AlarmsPercentage: "Alarms percentage",
                NumberOfActiveModacs: "Number of active modacs",
                NumberOfModacs: "Number of modacs",
                ModacAvailability: "Availability",
                SynchronizationsCount: "Synchronizations count",
                HTTPErrors: "HTTP errors",
                Details: "Details",

                // Login / Logout
                Logout: "Logout",
                EMAIL: "EMAIL",
                PASSWORD: "PASSWORD",
                LOGIN: "LOG IN",
                NUCLEOT_LOGIN: "NUCLEOT LOGIN",
                AreYouSureYouWantToLogout: "Are you sure you want to log out ?",
                Settings: "Settings",

                // Menu
                Dashboards: "Dashboards",
                Operations: "Operations",
                Contents: "Contents",
                Areas: "Areas",
                Inventory: "Inventory",
                Users: "Users",

                // Controls duration annotations
                NotCompliant: "Not compliant",
                Correct: "Correct",
                Compliant: "Compliant",
                notCompliants: "not compliants",
                corrects: "corrects",
                compliants: "compliants",


                //Graphs titles, legends
                ControlsDurationRepartition: "Controls duration repartition",
                RadiationLevel: "Radiation level",
                RadiationLevelDuringControls: "Radiation level during controls",
                Duration: "Duration",
                Percent: "Percent",
                NoDataOnThisPeriod: "No data on this period",
                PlaybacksCountPerContentType: "Playbacks count per content type",
                PlaybacksCountPerDeviceType: "Playbacks count per device type",
                HTTPCodesDistribution: "HTTP codes distribution",
                BrowsersDistribution: "Browsers distribution",
                HitsCountEvolution: "Hits count evolution",
                Availability: "Availability",
                Radiation: "Radiation",
                BackgroundNoise: "Background noise",

                // Tooltips
                Between: "Between",
                ControlsDuration: "Controls duration",

                // Device sas
                MediaspotInfos: "Mediaspot infos",
                Reference: "Reference",
                Serial: "Serial",
                HostIP: "IP addresses",
                HostMac: "Mac addresses",

                // Device Modac
                ModacInfos: "Modac infos",
                AviorIdentification: "Avior identification",
                LastCheck: "Last check",
                NextCheck: "Next check",
                ObservationID: "Observation ID",
                ModacBuildVersion: "Build Version",
                ModacSerial: "Modac serial",
                Uptime: "Uptime",
                FreeRam: "Free RAM",
                WifiFirmware: "Wi-Fi firmware",
                MediaspotBSSID: "Mediaspot BSSID",
                IPAddress: "IP Address",
                ModacUptime: "Modac uptime",
                CanberraAvailability: "AVIOR-2 availability",
                SerialNumber: "Serial number",

                // Probe
                Probe: "Probe",
                probe: "probe",
                Channel: "Channel",
                ProbeName: "Probe name",
                ProbeSerial: "Probe serial",
                DetectorInfo: "Detector info",
                DetectorInfoUnit: "Detector info unit",
                Transmitter: "Transmitter",
                Unit: "Unit",
                CalibrationCoefficient: "Calibration coefficient",
                CalibrationCoefficientUnit: "Calibration coefficient unit",
                NoDataOnThisProbe: "No data on this probe",

                // Alarm levels
                Low: "Low",
                High: "High",
                Saturate: "Saturate",

                // Measures list
                ModacMacAddress: "Modac Mac address",
                Date: "Date",
                ControlDuration: "Control duration",
                MaxMeasure: "Max measure",
                AverageMeasure: "Average measure",
                MeasuresList: "Measures list",

                // Supervision
                DataDateUpdate: "Data update dates",
                AverageMeasuresAndNumberOfAlarmsPerSas: "Average measures & number of alarms",
                FaultyProbes: "Faulty probes",
                NumberOfAlarms: "Number of alarms",
                DetectedInFaultySince: "Detected unavailable since",
                FaultyProbeDescription: "Probe are detected as faulty below 80% of availability",
                SasUpdatedMoreThan72HoursAgo: "Sas update more than 72h ago",
                SasUpdatedMoreThan7DaysAgo: "Sas update more than 7d ago",
                AviorAvailabilities: "Aviors availability",
                MeasureGreatherThan30cs: "Average measure > 30 c/s",
                MeasureGreatherThan70cs: "Average measure > 70 c/s",
                ProbesAvailabilities: "Probes availability",
                ExpectedTransmitter: "Expected transmitter",
                DetectedTransmitter: "Detected transmitter",
                State: "State",
                ProbesConnection: "Probes connection",
                Configuration: "Configuration",
                ModacDetectedAs: "Modac is detected as",
                ThisModacLogsDataInRealtime: "This modac logs data in realtime",
                Active: "Active",
                Inactive: "Inactive",
                InStock: "In stock",
                Available: "available",
                Unavailable: "unavailable",
                TheProbe: "Probe",
                IsDetectedAs: "is detected as",
                AndMustBe: "and  must be",
                LastConnectionIs: "Last connection is",
                ThisModacIsInactive: "This Modac is inactive",
                Input: "Input",
                ProbeSettings: "Probe settings",
                Status: "Status",
                LastInfoReceived: "Last info received",
                Online: "Online",
                Offline: "Offline",
                OnlineModacDescription: "Collection device is online.",
                OfflineModacDescription: "Collection device is not online.",
                InactiveModacDescription: "Collection device is not active.",
                CollectModeIs: "Collect mode is",
                Realtime: "realtime",
                Disconnected: "disconnected",
                RadiameterState: "Radiameter state",
                ExpectedRadiationType: "Expected radiation type",
                Expected: "Expected",
                Detected: "Detected",

                MeasureDeviceIsAvailable: "Measure device is available.\nProbes connection is compliant :",
                MeasureDeviceNotAvailableAvior: "Measure device is unavailable.\n Please check electric connection, collection device presence and measure device state.",
                MeasureDeviceAvailableAviorShouldntBe: "Measure device is available but shouldn't be availabe.\n Please check settings interface.",
                MeasureDeviceBadProbeConnection: "Probes connection is not compliant :",
                MeasureDeviceInactive: "Measure device hasn't be enabled on settings interface.",

                PluggedProbe: "Plugged probe",
                UnpluggedProbe: "Unplugged probe",
                ExpectedConnection: "Expected connection",
                DetectedConnection: "Detected connection",

                Location: "Location",
                DateAgo: "{{date}} ago",

                // Alarms

                saturateAlarm: "saturate alarm",
                saturateAlarms: "saturate alarms",

                highAlarm: "high alarm",
                highAlarms: "high alarms",

                lowAlarm: "low alarm",
                lowAlarms: "low alarms",

                AllDataAreUpToDate: "Data are up to date.",
                NoAlarmAndCorrectAverageRadiaton: "No alarm and correct average radiation value.",
                AllAviorsAreAvailable: "All Aviors are available.",
                AllProbeSettingsAreCorrect: "All probe settings are correct.",
                MeasureDeviceAvailabilities: "Measure devices availability",
                Device: "Device",
                Loading: "Loading",

                none: "None",
                beta: "Beta",
                alpha: "Alpha",
                gamma: "Gamma",

                ManageYourOrganizationDevicesAssociation: "Manage your organization devices association",
                AddArea: "Add area",
                EditTheArea: "Edit area",
                AreYouSureYouWantToRemoveThisArea: "Are you sure you want to remove this area ?",
                AreYouSureYouWantToRemoveThisDevice: "Are you sure you want to remove this device ?",
                AreYouSureYouWantToRemoveThisMediaspot: "Are you sure you want to remove this mediaspot ? All modacs associated to this mediaspots will be dissociated too",
                AreYouSureYouWantToRemoveThisModac: "Are you sure you want to remove this modac ? All devices associated to this modac will be dissociated too.",
                AreYouSureYouWantToRemoveThisRadiameter: "Are you sure you want to remove this radiameter ? All probes associated to this radiameter will be dissociated too.",
                AreYouSureYouWantToRemoveThisProbe: "Are you sure you want to remove this probe ?",
                DropThisMediaspotOnAnAreaToBindIt: "Drop this mediaspot on an area to bind it",
                DropThisModacOnAMediaspotToBindTt: "Drop this modac on mediaspot to bind it",
                DropThisRadiameterOnAModacToBindIt: "Drop this radiameter on a modac to bind it",
                DropThisProbeOnARadiameterToBindIt: "Drop this probe on a radiameter to bind it",
                Equipment: "Equipment",
                YouHaveToPickAtLeastOneTypeOfDevice: "You have to pick at least one type of device",
                AreYouSureYouWantToReplace: "Are you sure you want to replace",
                With: "with",

                Yes: "Yes",
                No: "No",

                // Administration device search
                Type: "Type",
                Associated: "Associated",
                Radiameter: "Radiameter",
                radiameter: "radiameter",
                Search: "Search",
                All: "All",
                AnErrorOccurred: "An error occurred",
                AnErrorOccurredPleaseReload: "An error occurred, please reload",
                Reload: "Reload",

                // Super administration
                Provisionning: "Provisionning",
                ShowNotVisibleAreas: "Show no visible areas",
                ShowStoreAreas: "Show store areas",
                ShowArchiveDevices: "Show archived devices",

                // Dissociation modal
                Confirmation: "Confirmation",
                AreYouSureYouWantToDissociate: "Are you sure you want to dissociate",
                PleaseSelectAStockAreaToPutDeviceYouWantToDissociate: "Please select a stock area to put device you want to dissociate",
                CheckHereToResetInstallationOf: "Check here to dissociate all equipments of",
                Dissociate: "Dissociate",

                StoreNode: "Store",
                AreaLabelNotConform: "Area label contains unauthorized characters.",
                SearchResultsForTypes: "Search results for types",
                SearchResultsForType: "Search results for type",
                AssociatedOnly: "associated only",
                NotAssociatedOnly: "not associated only",
                WithReference: "with reference",
                WithSerial: "with serial",
                AnyDeviceMatchingWithSearchFilters: "No device matching with search filters",
                DeliveredDate: "Delivered date",
                InstallationDate: "Installation date",
                LogsType: "Logs type",
                ThisModacDoesntCommunicateWithConfiguredMediaspot: "This modac doesn't communicate with configured mediaspot",
                DetectedCommunicationWith: "Detected communication with",
                EngineOilTemperature: "Engine oil temperature",

                EcoDriving: "Eco-driving"
            }
        },
        fr: {
            translations: {
                NoNucleotDevicesAvailableOnThisArea: "Aucun appareil NucleoT disponible dans cette zone",
                NoAccessToPerimeter: "Pas d'accès aux informations de ce périmètre.",

                // Date range picker
                SelectPeriod: "Selectionnez une période",
                LastHours: "Dernières heures",
                Last24Hours: "Dernières 24h",
                Last48Hours: "Dernières 48h",
                Last7Days: "7 derniers jours",
                LastMonth: "Mois dernier",
                LastYear: "An dernier",

                // Tabs
                Measures: "Contrôles",
                Events: "Evènements",
                Devices: "Appareils",
                Back: "Retour",
                Supervision: "Supervision",

                // Interval selector
                PerHour: "Par heure",
                PerDay: "Par jour",
                d: 'j',

                // Cards
                ControlsCount: "Nombre de contrôles",
                AverageControlsDuration: "Durée moyenne des contrôles",
                Alarms: "Alarmes",
                AlarmsCount: "Alarmes",
                AlarmsPercentage: "Pourcentage d'alarmes",
                NumberOfActiveModacs: "Nombre de Modacs actifs",
                NumberOfModacs: "Nombre de Modacs",
                ModacAvailability: "Taux de disponibilité",
                SynchronizationsCount: "Nombre de synchronisations",
                HTTPErrors: "Erreurs HTTP",
                Details: "Détails",

                // Login / Logout
                Logout: "Déconnexion",
                EMAIL: "EMAIL",
                PASSWORD: "MOT DE PASSE",
                LOGIN: "CONNEXION",
                NUCLEOT_LOGIN: "CONNEXION NUCLEOT",
                AreYouSureYouWantToLogout: "Êtes-vous sûre de vouloir vous déconnecter ?",
                Settings: "Paramètres",

                //Menu
                Dashboards: "Tableaux de bord",
                Operations: "Exploitation",
                Contents: "Contenus",
                Areas: "Zones",
                Inventory: "Inventaire",
                Users: "Utilisateurs",

                // Controls duration annotations
                NotCompliant: "Non conforme",
                Correct: "Correcte",
                Compliant: "Conforme",
                notCompliants: "non conformes",
                corrects: "correctes",
                compliants: "conformes",

                //Graphs titles, legends
                ControlsDurationRepartition: "Répartition des durées de contrôles",
                RadiationLevel: "Niveau de radiation",
                RadiationLevelDuringControls: "Niveau de radiation pendant les contrôles",
                Duration: "Durée",
                Percent: "Pourcentage",
                NoDataOnThisPeriod: "Pas de données sur cette période",
                PlaybacksCountPerContentType: "Nombre de lectures par types de contenu",
                PlaybacksCountPerDeviceType: "Nombre de lectures par types d'appareil",
                HTTPCodesDistribution: "Distribution des codes HTTP",
                BrowsersDistribution: "Distribution des navigateurs",
                HitsCountEvolution: "Evolution du nombre d'appels API",
                Availability: "Disponibilité",
                Radiation: "Radiation",
                BackgroundNoise: "Bruit de fond",

                // Tooltips
                Between: "Entre",
                ControlsDuration: "Durée des contrôles",

                // Device sas
                MediaspotInfos: "Informations mediaspot",
                Reference: "Référence",
                Serial: "Numéro de série",
                HostIP: "Adresses IP",
                HostMac: "Adresses Mac",

                // Device Modac
                ModacInfos: "Informations du Modac",
                AviorIdentification: "Identification Avior",
                LastCheck: "Dernière vérification",
                NextCheck: "Prochaine vérification",
                ObservationID: "ID d'observation",
                ModacBuildVersion: "Version du firmware",
                ModacSerial: "Numéro Modac",
                Uptime: "Uptime",
                FreeRam: "Mémoire libre",
                WifiFirmware: "Firmware Wi-Fi",
                MediaspotBSSID: "BSSID Mediaspot",
                IPAddress: "Adresse IP",
                ModacUptime: "Uptime Modac",
                CanberraAvailability: "Disponibilité de l'AVIOR-2",
                SerialNumber: "Numéro de série",

                // Probe
                Probe: "Sonde",
                probe: "sonde",
                Channel: "Canal",
                ProbeName: "Nom de la sonde",
                ProbeSerial: "Numéro de la sonde",
                DetectorInfo: "Information détecteur",
                DetectorInfoUnit: "Unité d'information de la sonde",
                Transmitter: "Transmetteur",
                Unit: "Unité",
                CalibrationCoefficient: "Coefficient de calibration",
                CalibrationCoefficientUnit: "Unité du coefficient de calibration",
                NoDataOnThisProbe: "Pas de données sur cette sonde",

                // Alarm levels
                Low: "Bas",
                High: "Haut",
                Saturate: "Saturation",

                // Measures list
                ModacMacAddress: "Adresse Mac Modac",
                Date: "Date",
                ControlDuration: "Durée du contrôle",
                MaxMeasure: "Mesure max",
                AverageMeasure: "Mesure moyenne",
                MeasuresList: "Liste des contrôles",

                // Supervision
                DataDateUpdate: "Dates de mise à jour des données",
                AverageMeasuresAndNumberOfAlarmsPerSas: "Mesures moyennes & nombre d'alarmes",
                FaultyProbes: "Sondes en défaut",
                NumberOfAlarms: "Nombre d'alarmes",
                DetectedInFaultySince: "Détectée indisponible depuis",
                FaultyProbeDescription: "Les sondes sont détectées comme defectueuses en dessous de 80% de disponibilité",
                SasUpdatedMoreThan72HoursAgo: "Mise à jour > 72h",
                SasUpdatedMoreThan7DaysAgo: "Mise à jour > 7j",
                AviorAvailabilities: "Disponibilité des Aviors",
                MeasureGreatherThan30cs: "Mesure moyenne > 30 c/s",
                MeasureGreatherThan70cs: "Mesure moyenne > 70 c/s",
                ProbesAvailabilities: "Disponibilité des sondes",
                ExpectedTransmitter: "Transmetteur attendu",
                DetectedTransmitter: "Transmetteur constaté",
                State: "Etat",
                ProbesConnection: "Connexion des sondes",
                Configuration: "Configuration",
                ModacDetectedAs: "Le modac est détecté comme",
                ThisModacLogsDataInRealtime: "Ce modac enregistre les données en temps réel",
                Active: "Actif",
                Inactive: "Inactif",
                InStock: "En stock",
                Available: "disponible",
                Unavailable: "indisponible",
                TheProbe: "La sonde",
                IsDetectedAs: "est détectée comme",
                AndMustBe: "et doit être",
                LastConnectionIs: "Le dernier connexion était le ",
                ThisModacIsInactive: "Ce Modac est inactif",
                Input: "Entrée",
                ProbeSettings: "Paramétrage des sondes",
                Status: "Statut",
                LastInfoReceived: "Dernière information reçue le",
                Online: "En ligne",
                Offline: "Hors ligne",
                OnlineModacDescription: "Le dispositif de collecte est en ligne.",
                OfflineModacDescription: "Le dispositif de collecte n'est pas en ligne.",
                InactiveModacDescription: "Le dispositif de collecte n'est pas actif.",
                CollectModeIs: "Le mode de collecte est",
                Realtime: "Temps réel",
                Disconnected: "Déconnecté",
                RadiameterState: "Etat du radiamètre",
                ExpectedRadiationType: "Type de rayonnement attendu",
                Expected: "Attendu",
                Detected: "Détecté",

                MeasureDeviceIsAvailable: "Le dispositif de mesure est disponible.\nLe branchement des sondes est conforme :",
                MeasureDeviceNotAvailableAvior: "Le dispositif de mesure n'est pas disponible.\nVérifier le branchement électrique, la présence du dispositif de collecte ainsi que l'état du dispositif de mesure",
                MeasureDeviceAvailableAviorShouldntBe: "Le dispositif de mesure est pas disponible mais ne devrait pas l'être.\nVérifier l'interface de paramétrage.",
                MeasureDeviceBadProbeConnection: "Le branchement des sondes n'est pas conforme :",
                MeasureDeviceInactive: "Le dispositif de mesure n'a pas été activé sur l'interface de paramétrage.",

                PluggedProbe: "Sonde branchée",
                UnpluggedProbe: "Sonde non branchée",
                ExpectedConnection: "Branchement attendu",
                DetectedConnection: "Branchement constaté",

                Location: "Emplacement",
                DateAgo: "Il y a {{date}}",

                // Alarms

                saturateAlarm: "alarme de saturation",
                saturateAlarms: "alarmes de saturation",

                highAlarm: "alarme haute",
                highAlarms: "alarmes hautes",

                lowAlarm: "alarme basse",
                lowAlarms: "alarmes basses",

                SaturateAlarm: "Alarme de saturation",
                HighAlarm: "Alarme haute",
                LowAlarm: "Alarme basse",
                SaturateAlarms: "Alarmes de saturation",
                HighAlarms: "Alarmes haute",
                LowAlarms: "Alarmes basse",

                AllDataAreUpToDate: "Toutes les données sont à jour",
                NoAlarmAndCorrectAverageRadiaton: "Aucune alarme et valeur moyenne de radiation correcte.",
                AllAviorsAreAvailable: "L'ensemble des Aviors est disponible.",
                AllProbeSettingsAreCorrect: "Toutes les sondes sont correctement configurées.",
                MeasureDeviceAvailabilities: "Disponibilité des dispositifs de mesure",
                Device: "Appareil",
                Loading: "Chargement",

                none: "Aucun",
                beta: "Beta",
                alpha: "Alpha",
                gamma: "Gamma",

                ManageYourOrganizationDevicesAssociation: "Gérez l'association de vos équipement dans votre organisation",
                AddArea: "Ajouter une zone",
                EditTheArea: "Editer la zone",
                AreYouSureYouWantToRemoveThisArea: "Êtes-vous sûre de vouloir supprimer cette zone ?",
                AreYouSureYouWantToRemoveThisDevice: "Êtes-vous sûre de vouloir supprimer cet équipement ?",
                AreYouSureYouWantToRemoveThisMediaspot: "Êtes-vous sûre de vouloir supprimer ce mediaspot. L'ensemble des modacs y étant associés vont également être dissociés.",
                AreYouSureYouWantToRemoveThisModac: "Êtes-vous sûre de vouloir supprimer ce modac. L'ensemble des équipements y étant associés vont également être dissociés.",
                AreYouSureYouWantToRemoveThisRadiameter: "Êtes-vous sûre de vouloir supprimer ce radiamètre. L'ensemble des sondes y étant associées vont également être dissociées.",
                AreYouSureYouWantToRemoveThisProbe: "Êtes-vous sûre de vouloir supprimer cette sonde.",
                DropThisMediaspotOnAnAreaToBindIt: "Déplacer ce mediaspot sur une zone pour le lier",
                DropThisModacOnAMediaspotToBindTt: "Déplacer ce modac sur un mediaspot pour le lier",
                DropThisRadiameterOnAModacToBindIt: "Déplacer ce radiamètre sur un modac pour le lier",
                DropThisProbeOnARadiameterToBindIt: "Déplacer cette sonde sur un radiamètre pour la lier",
                Equipment: "Équipements",
                YouHaveToPickAtLeastOneTypeOfDevice: "Vous devez sélectionner au moins un type d'équipement",
                AreYouSureYouWantToReplace: "Êtes-vous sûre de vouloir remplacer",
                With: "avec",

                Yes: "Oui",
                No: "Non",

                // Administration device search
                Type: "Type",
                Associated: "Associé",
                Radiameter: "Radiamètre",
                radiameter: "radiamètre",
                Search: "Rechercher",
                All: "Tous",
                AnErrorOccurred: "Une erreur s'est produite",
                AnErrorOccurredPleaseReload: "Une erreur s'est produite, veuillez recharger",
                Reload: "Recharger",

                // Super administration
                Provisionning: "Approvisionnement",
                ShowNotVisibleAreas: "Afficher les zones non visibles",
                ShowStoreAreas: "Afficher les zones de stockage",
                ShowArchiveDevices: "Afficher les appareils archivés",

                // Dissociation modal
                Confirmation: "Confirmation",
                AreYouSureYouWantToDissociate: "Êtes-vous sûre de vouloir dissocier",
                PleaseSelectAStockAreaToPutDeviceYouWantToDissociate: "Veuillez sélectionner une zone de réserve où mettre l'appareil que vous souhaitez dissocier",
                CheckHereToResetInstallationOf: "Cochez ici pour dissocier l'ensemble des équipements de",
                Dissociate: "Dissocier",

                StoreNode: "Réserve",
                AreaLabelNotConform: "Le nom de la zone contient des caractères non autorisés",
                SearchResultsForTypes: "Résultats de recherche pour les types",
                SearchResultsForType: "Résultats de recherche pour le type",
                AssociatedOnly: "associé uniquement",
                NotAssociatedOnly: "non associés uniquement",
                WithReference: "avec la référence",
                WithSerial: "avec le n° de série",
                AnyDeviceMatchingWithSearchFilters: "Aucun appareil correspondant aux filtres de recherche",
                DeliveredDate: "Date de livraison",
                InstallationDate: "Date d'installation",
                LogsType: "Type de logs",
                ThisModacDoesntCommunicateWithConfiguredMediaspot: "Ce modac ne communique pas avec le mediaspot configuré",
                DetectedCommunicationWith: "Communication détectée avec",
                EngineOilTemperature: "Température d'huile moteur",

                EcoDriving: "Eco-conduite"
            }
        }
    },
    load: "languageOnly",
    fallbackLng: "en",
    debug: false,
    lng: localStorage.getItem("language"),
    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ","
    },

    react: {
        wait: true
    }
});

export default i18n;
