import React, {Component} from "react";
import classes from "../Dashboard.module.css"

import Tabs from "../../navigation/tabs/Tabs"

import DashboardOverview from "./DashboardOverview"
import DriversList from "./DriversList/DriversList"
import DriverDetails from "./DriverDetails/DriverDetails"
import VehicleDetails from "./VehicleDetails/VehicleDetails"

import i18n from "../../i18n";
import { Redirect, Route, Switch } from "react-router-dom";
import Tree from "../tree/Tree";
import VehiclesList from "./Vehicles/VehiclesList";
import ReactTooltip from "react-tooltip";
import {subDays} from 'date-fns/fp'
import Interval from "../../requests/elastic_search/Interval";


class DashboardEcoDriving extends Component {

    constructor(props) {
        super(props)
    
        const now = new Date(Date.now())
        const oneDayAgo = subDays(1, now)
        if(!window.location.pathname.startsWith("/dashboard/operations/supervision")){
            oneDayAgo.setHours(0,0,0,0);
        }
    

        this.state = {
            interval: Interval.HOUR,
            startDatePeriodTimestamp: oneDayAgo.getTime(),
            endDatePeriodTimestamp: now.getTime(),

            measuresUnit: undefined,
            tabs: [
                {
                    name: i18n.t("Vue d'ensemble"),
                    path: "/dashboard/eco-driving/overview"
                },
                {
                    name: i18n.t("Conducteurs"),
                    path: "/dashboard/eco-driving/drivers"
                },{
                    name: i18n.t("Véhicules"),
                    path: "/dashboard/eco-driving/vehicles"
                }],
        }
    }

    async componentDidMount() {
        this.updateCurrentTabIfNeeded()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.updateCurrentTabIfNeeded()
    }


    updateCurrentTabIfNeeded = () => {
        let tab;
        const pathName = window.location.pathname
        if(pathName === "/dashboard/eco-driving/overview"){
                tab = {...this.state.tabs[0]}
        }else if(pathName.startsWith("/dashboard/eco-driving/drivers")){
            tab = {...this.state.tabs[1]}
        }else if(pathName.startsWith("/dashboard/eco-driving/vehicles")){
            tab = {...this.state.tabs[2]}
        }
        
        if(JSON.stringify(this.state.selectedTab) !== JSON.stringify(tab)){
            this.setState({
                selectedTab: tab
            })
        }
    }


    onLayoutChange = (layout, layouts) => {
        saveToLS("layouts", layouts);
        this.setState({ layouts });
    }

    onTabChange = (selectedTab) => {
        this.setState({selectedTab: selectedTab})
    }

    onSelectArea = (area, nodeId, nodePath) => {
        this.setState({selectedArea: area, selectedNodeId: nodeId, selectedAreaPath: nodePath})
    }

    onSelectModac = (modacNode, nodeId, nodePath) => {
        if(window.location.pathname === "/dashboard/operations/supervision"){
            const modacMatchingWithModacNode = this.state.modacs.find(modac => modac.pk === nodeId && modac.status === modacNode.status)
            this.setState({selectedModac: modacMatchingWithModacNode !== undefined ? modacMatchingWithModacNode : modacNode})
        }else {
            this.onSelectArea(modacNode, nodeId, nodePath)
        }
    }

    onUnselectModac = () => {
        this.setState({selectedModac: undefined})
    }

    // onSelectDriver = (driver) => {

    // }


    render() {
        let deviceSerial = undefined;
        if(this.props.selectedArea !== undefined && this.props.selectedArea.mediaspot !== undefined){
            deviceSerial = this.props.selectedArea.mediaspot.serial
        }

        return (
            <>
                <Tabs
                    startDatePeriodTimestamp={this.state.startDatePeriodTimestamp}
                    endDatePeriodTimestamp={this.state.endDatePeriodTimestamp}
                    onPeriodChange={this.onPeriodChange}
                    onIntervalChanged={this.onIntervalChanged}
                    interval={this.state.interval}
                    onSelect={this.onTabChange}
                    tabs={this.state.tabs}
                    selectedTab={this.state.selectedTab}
                    onFilterTap={this.onFilterTap}
                    displayOnlyDateShortcuts={window.location.pathname.startsWith("/dashboard/operations/supervision")}/>


                <div className={classes.DashboardContainer}>  

                    <div className={classes.DashboardDevicesTreeContainer}>
                        <Tree autoExpandAllAreas={true} isAreaManagement={false} hideVisibleNodes={true} onFactoriesLoaded={this.onFactoriesLoaded} onModacFetched={this.onModacFetched} onSelectModac={this.onSelectModac} alreadySelectedNodeId={this.state.selectedNodeId} onSelect={this.onSelectArea} />
                    </div>

                    <Switch>
                        <Redirect exact from={"/dashboard/eco-driving/"} to={"/dashboard/eco-driving/overview"}/>

                        <Route exact path={"/dashboard/eco-driving/overview"} render={() =>
                            <DashboardOverview/>
                        }/>

                        <Route exact path={"/dashboard/eco-driving/drivers"} render={() =>
                            <DriversList onSelectDriver={this.onSelectDriver}/>
                        }/>

                        <Route path={"/dashboard/eco-driving/drivers/:id"} render={(props) =>
                            <DriverDetails id={props.match.params.id}/>
                        }/>
                            

                        
                        <Route exact path={"/dashboard/eco-driving/vehicles"} render={() =>
                            <VehiclesList />
                        }/>

                        <Route path={"/dashboard/eco-driving/vehicles/:id"} render={(props) =>
                            <VehicleDetails fromList={true} id={props.match.params.id}/>
                        }/>
                    </Switch>
                    <ReactTooltip  effect="solid" />

            </div>
            </>
        )
    }
}

const saveToLS = (key, value) => {
    if (localStorage) {
        localStorage.setItem("rgl-eco-driving",
            JSON.stringify({
                [key]: value
            })
        );
    }
}

const getFromLS = (key) => {
    let ls = {};
    if (localStorage) {
        try {
            ls = JSON.parse(localStorage.getItem("rgl-eco-driving")) || {};
        } catch (e) {
            /*Ignore*/
        }
    }
    return ls[key];
}

export default DashboardEcoDriving
