import React, {Component} from "react"
import classes from "./Menu.module.css"
import classesMenuItem from "./MenuItem.module.css"
import {faChartArea} from '@fortawesome/free-solid-svg-icons'
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Trans} from "react-i18next";

import NucleotLogoText from "../../assets/img/nucleot_icon.svg"
import {isAdmin, isSuperAdmin} from "../../helpers/AuthHelper";

import {withRouter} from "react-router-dom"

class Menu extends Component {

    state = {
        dashboardsMenuExpanded: true,
        adminMenuExpanded: false,
    };

    componentDidMount() {
        this.setState({
            isAdmin: isAdmin(),
            isSuperAdmin: isSuperAdmin()
        })
    }



    render(){
        return (

            <div className={classes.MenuContainer}>


                <img className={classes.HeaderLogo} alt={"Nucleot"} src={NucleotLogoText}/>

                <div className={classes.MenuHeaderSeparator}/>

                <Link to={"/dashboard/operations"}>
                    <div className={[classesMenuItem.MenuItemContainer, classesMenuItem.MenuItemContainerWithSubmenus, (window.location.pathname.startsWith("/dashboard/")) ?  classesMenuItem.MenuItemActive : undefined].join(' ')}>
                        <div className={classesMenuItem.SubmenuLink}>
                            <FontAwesomeIcon icon={faChartArea}/>&nbsp;&nbsp;<Trans>Dashboards</Trans>
                        </div>
                    </div>
                </Link>

                <>
                    <div className={[classesMenuItem.SubmenuItemContainer, window.location.pathname.startsWith("/dashboard/operations") ?  classesMenuItem.SubmenuItemActive : undefined].join(' ')}>
                        <Link
                            to={"/dashboard/operations"}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Trans>Operations</Trans></Link>
                    </div>
                    <div className={[classesMenuItem.SubmenuItemContainer, window.location.pathname.startsWith("/dashboard/eco-driving") ?  classesMenuItem.SubmenuItemActive : undefined].join(' ')}>
                        <Link
                            to={"/dashboard/eco-driving"}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Trans>EcoDriving</Trans></Link>
                    </div>
                    <div className={[classesMenuItem.SubmenuItemContainer, "/dashboard/medias" === window.location.pathname ?  classesMenuItem.SubmenuItemActive : undefined].join(' ')}>
                        <Link to={"/dashboard/medias"}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Trans>Contents</Trans></Link>
                    </div>
                </>

                {this.state.isAdmin === true ?
                    <>
                        <Link to={"/admin/areas"}>
                            <div className={[classesMenuItem.MenuItemContainer, classesMenuItem.MenuItemContainerWithSubmenus, (window.location.pathname.startsWith("/admin/")) ?  classesMenuItem.MenuItemActive : undefined].join(' ')}>
                                <div className={classesMenuItem.SubmenuLink}><FontAwesomeIcon icon={faChartArea}/>&nbsp;&nbsp;<Trans>Administration</Trans></div>
                            </div>
                        </Link>
                        <>
                            <div className={[classesMenuItem.SubmenuItemContainer, window.location.pathname.startsWith("/admin/areas") ?  classesMenuItem.SubmenuItemActive : undefined].join(' ')}>
                                <Link to={"/admin/areas"}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Trans>Bus</Trans>/<Trans>Devices</Trans></Link>
                            </div>
                            <div className={[classesMenuItem.SubmenuItemContainer, "/admin/inventory" === window.location.pathname ?  classesMenuItem.SubmenuItemActive : undefined].join(' ')}>
                                <Link to={"/admin/inventory"}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Trans>Inventory</Trans></Link>
                            </div>
                            <div className={[classesMenuItem.SubmenuItemContainer, window.location.pathname.startsWith("/admin/users") ?  classesMenuItem.SubmenuItemActive : undefined].join(' ')}>
                                <Link to={"/admin/users"}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Trans>Users</Trans></Link>
                            </div>
                        </>
                    </>
                    : undefined }

                {this.state.isSuperAdmin === true ?
                    <>
                        <Link to={"/superadmin/provisionning"}>
                            <div className={[classesMenuItem.MenuItemContainer, classesMenuItem.MenuItemContainerWithSubmenus, (window.location.pathname.startsWith("/superadmin/")) ?  classesMenuItem.MenuItemActive : undefined].join(' ')}>
                                <div className={classesMenuItem.SubmenuLink}><FontAwesomeIcon icon={faChartArea}/>&nbsp;&nbsp;<Trans>Super admin</Trans></div>
                            </div>
                        </Link>
                        <>
                            <div className={[classesMenuItem.SubmenuItemContainer, window.location.pathname.startsWith("/superadmin/provisionning") ?  classesMenuItem.SubmenuItemActive : undefined].join(' ')}>
                                <Link to={"/superadmin/provisionning"}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Trans>Provisionning</Trans></Link>
                            </div>
                        </>
                    </>
                    : undefined }
                <label className={classes.MenuAppVersion}>Version {process.env.REACT_APP_VERSION}</label>
            </div>
        )
    }
}

export default withRouter(Menu);
