import React, {Component} from "react"
import Card from "../card/Card"
import classes from "../Dashboard.module.css"
import ModacsUpdatesTime from "../supervision/UpdatesTimeModac"
import WarningAttention from "./WarningPoints/WarningPoints"

import {Responsive, WidthProvider} from "react-grid-layout";
import { Trans } from "react-i18next";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

class DashboardSupervision extends Component {

    constructor(props) {
        super(props)
        const originalLayouts = getFromLS("layouts") || {};

        this.state = {
            layouts: JSON.parse(JSON.stringify(originalLayouts)),
        }
    }

    render(){
        return (
            <div style={{marginTop: "50px"}}>
                <ResponsiveReactGridLayout
                    rowHeight={100}
                    cols={{ lg: 12, md: 12, sm: 6, xs: 4, xxs: 2 }}
                    layouts={this.state.layouts}
                    onLayoutChange={this.onLayoutChange}
                    onBreakpointChange={this.onBreakpointChange}>

                    <div key={"card_vehicles_amount"} data-grid={{ w: 3, h: 1, x: 0, y: 0, static: true }}>
                        <Card title="Nombre de véhicules" backgroundColor="#2F82E0" value="5"/>
                    </div>


                    <div key={"card_connected_drivers_amount"} data-grid={{ w: 3, h: 1, x: 6, y: 0, static: true }}>
                        <Card title="Véhicules connectés" backgroundColor="#2F82E0" value="2"/>
                    </div>
                    
                    <div key={"card_drivers_amount"} data-grid={{ w: 3, h: 1, x: 3, y: 0, static: true }}>
                        <Card title="Conducteurs" backgroundColor="#2F82E0" value="6"/>
                    </div>

                    <div key={"card_consommation_amount"} data-grid={{ w: 3, h: 1, x: 9, y: 0, static: true }}>
                        <Card title="Consommation totale" backgroundColor="#2F82E0" value="14937L"/>
                    </div>

                    
                    <div className={classes.ChartContainer} style={{overflow: "auto"}} key={"updates_time_modac"} data-grid={{ w: 6, h: 3, x: 0, y: 1, static: true }}>
                        
                        <label><Trans>DataDateUpdate</Trans></label>
                        <div className={classes.DashboardDevicesTitleSeparator}/>

                        <div className={classes.DashboardDevicesGroupContent}>
                            {<ModacsUpdatesTime nodePath={this.props.selectedAreaPath} modacs={[]} factories={this.props.factories} />}
                        </div>
                    </div>

                    <div className={classes.ChartContainer} style={{overflow: "auto"}} key={"warning_points"} data-grid={{ w: 6, h: 3, x: 6, y: 1, static: true }}>
                        
                        <label><Trans>Liste des points d'attention</Trans></label>
                        <div className={classes.DashboardDevicesTitleSeparator}/>

                        <div className={classes.DashboardDevicesGroupContent}>
                            {<WarningAttention/>}
                        </div>
                    

                    </div>

                </ResponsiveReactGridLayout>
            </div>
        )
    }
}

const saveToLS = (key, value) => {
    if (localStorage) {
        localStorage.setItem("rgl-supervision",
            JSON.stringify({
                [key]: value
            })
        );
    }
}

const getFromLS = (key) => {
    let ls = {};
    if (localStorage) {
        try {
            ls = JSON.parse(localStorage.getItem("rgl-supervision")) || {};
        } catch (e) {
            /*Ignore*/
        }
    }
    return ls[key];
}


export default DashboardSupervision