import React, {Component} from "react"
import EvolutionAverageNotation from "./EvolutionAverageNotation/EvolutionAverageNotation"

import {Responsive, WidthProvider} from "react-grid-layout";


const ResponsiveReactGridLayout = WidthProvider(Responsive);

class DashboardOverview extends Component {

    constructor(props) {
        super(props)
        const originalLayouts = getFromLS("layouts") || {};

        this.state = {
            layouts: JSON.parse(JSON.stringify(originalLayouts)),
        }
    }

    render(){
        return (
            <div style={{marginTop: "50px"}}>
                <ResponsiveReactGridLayout
                    rowHeight={100}
                    cols={{ lg: 12, md: 12, sm: 6, xs: 4, xxs: 2 }}
                    layouts={this.state.layouts}
                    onLayoutChange={this.onLayoutChange}
                    onBreakpointChange={this.onBreakpointChange}>


                    <div key={"evolution_average_notation"} data-grid={{ w: 12, h: 6, x: 0, y: 0, static: true }}>
                        <EvolutionAverageNotation />
                    </div>
                
                   
                </ResponsiveReactGridLayout>
                
                

            </div>
        )
    }
}

const saveToLS = (key, value) => {
    if (localStorage) {
        localStorage.setItem("rgl-eco-driving-overview",
            JSON.stringify({
                [key]: value
            })
        );
    }
}

const getFromLS = (key) => {
    let ls = {};
    if (localStorage) {
        try {
            ls = JSON.parse(localStorage.getItem("rgl-eco-driving-overview")) || {};
        } catch (e) {
            /*Ignore*/
        }
    }
    return ls[key];
}

export default DashboardOverview