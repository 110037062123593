import timeGraphZoomDefaultProps from "./TimeGraphZoomDefaultProps";
import xAxeTimeDefaultProps from "./XAxeTimeDefaultProps";
import "chartjs-plugin-annotation"

export const graphProps = (dates, interval, startDate, endDate) => {
    let minDate = undefined;
    let maxDate = undefined;
    if(dates !== undefined && dates.length > 0 && dates[0].getTime() !== undefined){
        minDate = dates[0].getTime();
        maxDate = dates[dates.length-1].getTime();
    }

    startDate = minDate
    endDate = maxDate

    return {
        type: 'line',
        data: {},
        labels: dates,
        options: {
            plugins:{
                zoom: false
            },
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: false
            },
            pan: {
                enabled: true,
                mode: 'x',
            },
            zoom: timeGraphZoomDefaultProps(maxDate, interval),
            scales: {
                xAxes: [
                    xAxeTimeDefaultProps(minDate, maxDate, interval, startDate, endDate)
                ],
                yAxes: [{
                    ticks: {
                        max: 120,
                        min: 0,
                        // stepSize: 5000,
                    }
                }]
            },
            annotation: {
                events: ["click", "mouseenter", "mouseleave"],
                annotations: [
                    {
                        drawTime: "afterDatasetsDraw",
                        id: "vline_1",
                        type: "line",
                        mode: "horizontal",
                        scaleID: "y-axis-0",
                        value: 95,
                        borderColor: "red",
                        borderWidth: 1,
                        label: {
                            backgroundColor: "red",
                            content: "> 95°C => surchauffe d'huile",
                            enabled: true
                        },
                        onMouseenter: function(e) {
                            this.chartInstance.options.annotation.annotations.push(
                                {
                                    drawTime: "afterDatasetsDraw",
                                    id: "hline4",
                                    type: "line",
                                    mode: "horizontal",
                                    scaleID: "y-axis-0",
                                    value: 95,
                                    borderColor: "#00000000",
                                    borderWidth: 1,
                                    label: {
                                        xAdjust: 120,
                                        backgroundColor: "rgba(0,0,0,0.82)",
                                        content: `0 dépassement de seuil`,
                                        enabled: true,
                                        position: "top"
                                    }
                                });
                            this.chartInstance.update();
                        },
                        onMouseleave: function (e) {
                            this.chartInstance.options.annotation.annotations.pop()
                            this.chartInstance.update();
                        }
                    }
                ]
            },
        }
    }
}
