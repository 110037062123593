import L from 'leaflet';

export const greenBusIcon = new L.Icon({
    iconUrl: require('../assets/img/bus-green.svg'),
    iconRetinaUrl: require('../assets/img/bus-green.svg'),
    iconAnchor: [30, 76],
    popupAnchor: [0, -76],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(60, 76),
    className: 'leaflet-div-icon'
});

export const redBusIcon = new L.Icon({
    iconUrl: require('../assets/img/bus-red.svg'),
    iconRetinaUrl: require('../assets/img/bus-red.svg'),
    iconAnchor: [30, 76],
    popupAnchor: [0, -76],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(60, 76),
    className: 'leaflet-div-icon'
});

export const orangeBusIcon = new L.Icon({
    iconUrl: require('../assets/img/bus-orange.svg'),
    iconRetinaUrl: require('../assets/img/bus-orange.svg'),
    iconAnchor: [30, 76],
    popupAnchor: [0, -76],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(60, 76),
    className: 'leaflet-div-icon'
});


