import React, {Component} from "react"
import PropTypes from "prop-types"
import { getDrivers } from "../../../requests/GetDrivers"
class DriversNotation extends Component {


    state = {
        drivers: undefined
    }

    async componentDidMount(){
        const driversResponse = await getDrivers()
        this.setState({drivers: driversResponse})
    }

    render(){

        if(!this.state.drivers){
            return <label>Chargement...</label>
        }

        const drivers = this.state.drivers.sort((driver1, driver2) => {
            switch(this.props.type){
                case "global": return driver1.globalNotation > driver2.globalNotation ? (this.props.reverse === true ? 1 : -1) : (this.props.reverse === true ? -1 : 1);
                case "comfort": return driver1.comfort.notation > driver2.comfort.notation ? (this.props.reverse === true ? 1 : -1) : (this.props.reverse === true ? -1 : 1);
                case "brake": return driver1.brake.notation > driver2.brake.notation ? (this.props.reverse === true ? 1 : -1) : (this.props.reverse === true ? -1 : 1);
                case "freewheel": return driver1.freewheel.notation > driver2.freewheel.notation ? (this.props.reverse === true ? 1 : -1) : (this.props.reverse === true ? -1 : 1);
                case "engine_on_at_stop": return driver1.onStopEngine.notation > driver2.onStopEngine.notation ? (this.props.reverse === true ? 1 : -1) : (this.props.reverse === true ? -1 : 1);
            }
        })

        return (
            <table>
                <thead>
                    <tr>
                        <th>Note</th>
                        <th>Nom</th>    
                        <th>Distance (km)</th>

                        {this.props.type === "comfort" ?
                            <th>Points</th>
                        : null}

                        {this.props.type === "brake" ? <>
                            <th>Conso dynamique (MJ/100km)</th>
                        </> : null}
                        {this.props.type === "speed" ? <>
                            <th>Conso dynamique (MJ/100km)</th>
                        </> : null}

                        {this.props.type === "engine_on_at_stop" ? <>
                            <th>Temps passé moteur tournant à l'arrêt (secs)</th>
                            <th>Conso dynamique (MJ/100km)</th>
                        </> : null}            

                        {this.props.type === "freewheel" ? <>
                            <th>Roue libre (%)</th>
                        </> : null}
                    </tr>
                </thead>
                <tbody>
                    {
                        drivers.map(driver => {
                            return (
                                <tr>
                                    <td>
                                        {this.props.type === "global" ? driver.globalNotation : null}
                                        {this.props.type === "brake" ? driver.brake.notation : null}
                                        {this.props.type === "speed" ? driver.speed.notation : null}
                                        {this.props.type === "comfort" ? driver.comfort.notation : null}
                                        {this.props.type === "freewheel" ? driver.freewheel.notation : null}
                                        {this.props.type === "engine_on_at_stop" ? driver.onStopEngine.notation : null}
                                    </td>
                                    <td>{`${driver.firstname} ${driver.lastname}`}</td>
                                    <td>{`${driver.distance}`}</td>
                                    
                                    {this.props.type === "comfort" ?
                                        <td>{driver.comfort.points}</td>
                                    : null}

                                    {this.props.type === "brake" ? <>
                                        <td>{driver.brake.consumption}</td>
                                    </> : null}
                                    {this.props.type === "speed" ? <>
                                        <td>{driver.speed.consumption}</td>
                                    </> : null}

                                    {this.props.type === "engine_on_at_stop" ? <>
                                        <td>{driver.onStopEngine.durationInMin}</td>
                                        <td>{driver.onStopEngine.consumption}</td>
                                    </> : null}            

                                    {this.props.type === "freewheel" ? <>
                                        <td>{driver.freewheel.percentage}</td>
                                    </> : null}

                                </tr>
                            )
                        })
                    }
                    
                </tbody>
            </table>
        )
    }
}

DriversNotation.propTypes = {
    type: PropTypes.oneOf(["global", "brake", "speed", "comfort", "freewheel", "engine_on_at_stop"]),
    reverse: PropTypes.bool
}

export default DriversNotation