export const getVehicles = async () => {
    return {
        data: [
            {
                identifier: "694",
                serial: "CF-822-QA",
                odometer: 209001,
                lastContact: 1614689143000,
                totalConsumption: 13003,
                dynamicAverageConsumption: 3.4,
                onStopEngine: 290, // seconds
                averageConsumption: 39.2,
                lastPosition: {
                    lat: 49.623503,
                    lng: -1.622243,
                }
            },
            {
                identifier: "801",
                serial: "EX-872-IU",
                odometer: 18033,
                lastContact: 1614684143000,
                totalConsumption: 100034,
                dynamicAverageConsumption: 5.2,
                onStopEngine: 156, // seconds
                averageConsumption: 29.9,
                lastPosition: {
                    lat: 49.623503,
                    lng: -1.622243,
                    // lat: 49.182164,
                    // lng: -0.399136,
                }
            },
            {
                identifier: "802",
                serial: "BN-771-RK",
                odometer: 349072,
                lastContact: 1614629143000,
                totalConsumption: 83404,
                dynamicAverageConsumption: 2.9,
                onStopEngine: 121, // seconds
                averageConsumption: 31.8,
                lastPosition: {
                    lat: 49.623503,
                    lng: -1.622243,
                }
                
            },
        ]
    }
}