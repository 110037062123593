import {Redirect, Route, Switch} from "react-router-dom";
import Header from "./navigation/header/Header";
import NavigationMenu from "./navigation/menu/Menu";
import classes from "./index.module.css";
import DashboardMedias from "./dashboard/medias/DashboardMedias";
import DashboardOperations from "./dashboard/operations/DashboardOperations";
import DashboardEcoDriving from "./dashboard/eco_driving/DashboardEcoDriving";

import AdminAreas from "./admin/admin_areas/AdminAreas"
import AdminInventory from "./admin/inventory/Inventory"
import AdminUsers from "./admin/users/Users"
import Provisionning from "./superadmin/provisionning/Provisionning";

import React from "react";

import withAuth from "./hoc/withAuth/withAuth";
import Settings from "./settings/Settings";

const router = () => {
    return (
        <>
            <Header/>
            <NavigationMenu />
            <div className={classes.RootContainer}>
                <Switch>
                    {/* Dashboards */}
                    <Route path="/dashboard/operations" render={() => <DashboardOperations/>} />
                    <Route path="/dashboard/eco-driving" render={() => <DashboardEcoDriving/>} />
                    <Route exact path="/dashboard/medias" render={() => <DashboardMedias/>} />

                    {/* Administration */}
                    <Route path="/admin/areas" render={() => <AdminAreas/>} />
                    <Route path="/admin/inventory" render={() => <AdminInventory/>} />
                    <Route exact path="/admin/users" render={() => <AdminUsers/>} />

                    {/* Super administration */}
                    <Route exact path="/superadmin/provisionning" render={() => <Provisionning/>} />

                    {/* Settings */}
                    <Route exact path="/settings" render={() => <Settings/>} />

                    <Redirect from={"/"} to={"/dashboard/operations"} />
                </Switch>
            </div>
        </>
    )
}

export default withAuth(router)
