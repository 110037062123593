import timeGraphZoomDefaultProps from "./TimeGraphZoomDefaultProps";
import xAxeTimeDefaultProps from "./XAxeTimeDefaultProps";
import "chartjs-plugin-annotation"

export const graphProps = (dates, interval, startDatePeriodTimestamp, endDatePeriodTimestamp) => {
    let minDate = undefined;
    let maxDate = undefined;
    if(dates !== undefined && dates.length > 0 && dates[0].getTime() !== undefined){
        minDate = dates[0].getTime();
        maxDate = dates[dates.length-1].getTime();
    }

    return {
        type: 'line',
        data: {},
        labels: dates,
        options: {
            plugins:{
                zoom: false
            },
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: false
            },
            zoom: timeGraphZoomDefaultProps(maxDate, interval),
            scales: {
                xAxes: [
                    xAxeTimeDefaultProps(minDate, maxDate, interval, startDatePeriodTimestamp, endDatePeriodTimestamp)
                ],
                yAxes: [{
                    ticks: {
                        min: 0,
                        max: 5,
                        stepSize: 1
                    },
                    gridLines: {
                        display:false
                    }
                }]
            }
        }
    }
}
