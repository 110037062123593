import React, {useEffect} from "react"
import {useDrag} from 'react-dnd'
import {getEmptyImage} from "react-dnd-html5-backend";
import {NodeType} from "./NodeType";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAtom, faEye, faMicrochip, faWeight, faWifi} from "@fortawesome/free-solid-svg-icons";
import classes from "./DeviceSearch.module.css"
import {getI18n, Trans} from "react-i18next";

export default function DeviceRow({nodeType, id, serial, reference, isDeployed, onUpdate, associatedPath, fullObject, onShowClick}) {
    const [{opacity, dragHandlerId}, dragRef, preview] = useDrag({
        item: {id: id, type: "device", subtype: nodeType, serial: serial, update: onUpdate, isDeployed: isDeployed, fullObject},
        collect: (monitor) => ({
            opacity: monitor.isDragging() ? 0.7 : 1,
            dragHandlerId: monitor.getHandlerId()
        }),
        canDrag: !isDeployed
    })

    useEffect(() => {
        preview(getEmptyImage(), {captureDraggingState: true})
    }, [preview])


    // useImperativeHandle(ref, () => ({
    //     getDragHandlerId: () => dragHandlerId
    // }))

    return (
        <tr role={"tr"} key={id} id={id} ref={!isDeployed ? dragRef : undefined} className={isDeployed === true ? classes.DeviceSearchResultRowUndraggable : classes.DeviceSearchResultRowDraggable} style={{opacity}}>

            <td style={{cursor: "pointer"}} onClick={()=>onShowClick(fullObject)}><FontAwesomeIcon icon={faEye}/></td>
            <td>
                <>
                {nodeType === NodeType.MEDIASPOT ? <FontAwesomeIcon fixedWidth={true} icon={faWifi}/> : undefined}
                {nodeType === NodeType.MODAC ? <FontAwesomeIcon fixedWidth={true} icon={faMicrochip}/> : undefined}
                {nodeType === NodeType.RADIAMETER ? <FontAwesomeIcon fixedWidth={true} icon={faWeight}/> : undefined}
                {nodeType === NodeType.PROBE ? <FontAwesomeIcon fixedWidth={true} icon={faAtom}/> : undefined}
                </>
                &nbsp;&nbsp;{getI18n().t(nodeType.charAt(0).toUpperCase() + nodeType.slice(1))}
                {/*&nbsp;&nbsp;{nodeType.charAt(0).toUpperCase() + nodeType.slice(1)}*/}
            </td>
            <td>{reference}</td>
            <td>{serial}</td>
            <td><Trans>{isDeployed ? "Yes" : "No"}</Trans></td>
        </tr>
    )

}
