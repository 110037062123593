import React, {Component} from "react"
import PropTypes from "prop-types"
import driverTravelsClasses from "./DriverTravels.module.css"
import { getTravels } from "../../../../requests/GetTravels"
import { MapContainer, TileLayer, Polyline} from 'react-leaflet'

import BrakeImg from "../../../../assets/img/brake.svg"
import TurnImg from "../../../../assets/img/turn.svg"
import SpeedImg from "../../../../assets/img/speed.svg"
import TimeImg from "../../../../assets/img/time.svg"
import WheelImg from "../../../../assets/img/wheel.svg"
import { secToTextWithoutUselessUnit } from "../../../../helpers/DateHelper"


class DriverTravels extends Component {

    
    state = {
        travels: undefined,
        travelsError: undefined,
        selectedTravel: undefined
    }

    async componentDidMount(){
        const driverId = this.props.driverId

        const travelsResponse = await getTravels(driverId)
        if(travelsResponse.error){
            this.setState({
                travelsError: travelsResponse.error,
                travels: undefined
            })
            return
        }

        this.setState({
            travelsError: undefined,
            travels: travelsResponse.data,
            selectedTravel: travelsResponse.data && travelsResponse.data.length > 0 ? travelsResponse.data[0] : undefined
        })
    }



    render(){
        if(this.state.travels === undefined){
            return <label>Chargement...</label>
        }
        const travelLines = (this.state.selectedTravel && this.state.selectedTravel.travel) ? this.state.selectedTravel.travel : undefined
        return (

            <>
        
                <div className={driverTravelsClasses.DriverTravelsContainer}>
                    {this.state.travels.map(travel => {
                        const travelDurationSec = (travel.endTimestamp - travel.startTimestamp) / 1000
                        const startDate = new Date(travel.startTimestamp)
                        const endDate = new Date(travel.startTimestamp)
                        return (
                            <>
                            <div className={[driverTravelsClasses.DriverTravelContainer, this.state.selectedTravel === travel ? driverTravelsClasses.TravelItemSelected : undefined].join(" ")} onClick={() => this.setState({selectedTravel: travel})}>
                                <div>
                                    <table className={driverTravelsClasses.TravelTableItem} style={{width: "100%"}}>
                                        <tr>
                                            <td colSpan="4">{endDate.toLocaleDateString()}</td>
                                            <td colSpan="2"></td>
                                            <td colSpan="4">Bus n° {travel.vehicleId} ({travel.vehicleImmat})</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="5" className={driverTravelsClasses.TravelDurationLabel}>{secToTextWithoutUselessUnit(travelDurationSec)} ({startDate.getHours()}h{startDate.getMinutes()} - {endDate.getHours()}h{endDate.getMinutes()})</td>
                                            <td colSpan="3" className={driverTravelsClasses.TravelDistanceLabel}>{travel.distance / 1000} km</td>
                                            <td colSpan="2" className={driverTravelsClasses.TravelNotationLabel}>{travel.globalNotation} / 5</td>
                                        </tr>



                                        <tr className={driverTravelsClasses.DriverItemsNotationRow}>
                                            <td className={travel.brake.issuesCount === 1 ? driverTravelsClasses.WarningNotationImg : travel.brake.issuesCount > 1 ? driverTravelsClasses.CriticalNotationImg : driverTravelsClasses.OkNotationImg} colSpan="2"><img src={BrakeImg} alt="Freinage"/></td>
                                            <td className={travel.comfort.issuesCount === 1 ? driverTravelsClasses.WarningNotationImg : travel.comfort.issuesCount > 1 ? driverTravelsClasses.CriticalNotationImg : driverTravelsClasses.OkNotationImg} colSpan="2"><img src={TurnImg} alt="Turn"/></td>
                                            <td className={travel.speed.issuesCount === 1 ? driverTravelsClasses.WarningNotationLImg: (travel.speed.issuesCount > 1 ? driverTravelsClasses.CriticalNotationImg : driverTravelsClasses.OkNotationLImg)} colSpan="2"><img src={SpeedImg} alt="Speed"/></td>
                                            <td className={travel.onStopEngine.notation > 4 ? driverTravelsClasses.OkNotationImg : travel.onStopEngine.notation > 3 ? driverTravelsClasses.WarningNotationImg : driverTravelsClasses.CriticalNotationImg} colSpan="2"><img src={TimeImg} alt="Time"/></td>
                                            <td className={travel.freewheel.notation > 4 ? driverTravelsClasses.OkNotationImg : travel.freewheel.notation > 3 ? driverTravelsClasses.WarningNotationImg : driverTravelsClasses.CriticalNotationImg} colSpan="2"><img src={WheelImg} alt="Wheel"/></td>
                                        </tr>
                                        <tr className={driverTravelsClasses.DriverItemsNotationRow}>
                                            <td colSpan="2"><span className={travel.brake.issuesCount === 1 ? driverTravelsClasses.WarningNotationLabel : travel.brake.issuesCount > 1 ? driverTravelsClasses.CriticalNotationLabel : driverTravelsClasses.OkNotationLabel}>{travel.brake.issuesCount} freinage fort</span></td>
                                            <td colSpan="2"><span className={travel.comfort.issuesCount === 1 ? driverTravelsClasses.WarningNotationLabel : travel.comfort.issuesCount > 1 ? driverTravelsClasses.CriticalNotationLabel : driverTravelsClasses.OkNotationLabel}>{travel.comfort.issuesCount} {travel.comfort.issuesCount > 1 ? " virages forts" : "virage fort"}</span></td>
                                            <td colSpan="2"><span className={travel.speed.issuesCount === 1 ? driverTravelsClasses.WarningNotationLabel : (travel.speed.issuesCount > 1 ? driverTravelsClasses.CriticalNotationLabel : driverTravelsClasses.OkNotationLabel)}>{travel.speed.issuesCount} {travel.speed.issuesCount > 1 ? "vitesses élevées" : "vitesse élevée"}</span></td>
                                            <td colSpan="2"><span className={travel.onStopEngine.notation > 4 ? driverTravelsClasses.OkNotationLabel : travel.onStopEngine.notation > 3 ? driverTravelsClasses.WarningNotationLabel : driverTravelsClasses.CriticalNotationLabel}>Moteur tournant à l'arrêt {travel.onStopEngine.durationInMin} min</span></td>
                                            <td colSpan="2"><span className={travel.freewheel.notation > 4 ? driverTravelsClasses.OkNotationLabel : travel.freewheel.notation > 3 ? driverTravelsClasses.WarningNotationLabel : driverTravelsClasses.CriticalNotationLabel}>{travel.freewheel.percentage}% de roue libre</span></td>
                                        </tr>
                                    </table>
                                </div>

                            </div>
                            <div className={driverTravelsClasses.Separator}/>
                            </>
                        )
                    })}
                </div>
                

                <div style={{width: "50%"}}>

                    <MapContainer style={{width: "100%", height: "90%" }} center={[49.633731, -1.622137]} zoom={12} scrollWheelZoom={false}>
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/> 
                        
                        <Polyline key={"id"} positions={[
                            travelLines ? travelLines.map(travel => {
                                return [travel.from_lat, travel.from_long], [travel.to_lat, travel.to_long]
                            }) : undefined
                        ]} color={'red'} />
                        
                    </MapContainer>
                </div>
            </>
        )
    }
}

DriverTravels.propTypes = {
    id: PropTypes.number.isRequired
}

export default DriverTravels