import classes from "../../Dashboard.module.css"
import React, {Component} from "react" 
import { Trans } from "react-i18next"

class VehiclesList extends Component {

    state = {
        vehicles: [
            {
                identifier: "694",
                serial: "CF-822-QA",
                odometer: 209001,
                lastContact: 1614689143000,
                service: "" // ?
            },
            {
                identifier: "695",
                serial: "EX-872-IU",
                odometer: 18033,
                lastContact: 1614689143000,
                service: "" // ?
            },
            {
                identifier: "801",
                serial: "BN-771-RK",
                odometer: 349072,
                lastContact: 1614689143000,
                service: "EDET Mathieu" // ?
            },
            {
                identifier: "802",
                serial: "DD-332-TR",
                odometer: 88209,
                lastContact: 1614689143000,
                service: "" // ?
            },
            {
                identifier: "901",
                serial: "EE-322-UI",
                odometer: 307003,
                lastContact: 1614689143000,
                service: "" // ?
            }
        ]
    }

    render(){
        return (
            <div className={classes.ChartContainer} style={{overflow: "auto", width: "100%", marginTop: "50px"}}>
                
                <label><Trans>Liste des véhicules</Trans></label>
                
                <div className={classes.DashboardDevicesTitleSeparator}/>

                <div className={classes.DashboardDevicesGroupContent}>
                
                    <table>
                        <thead>
                            <tr>
                                <th>Numéro</th>
                                <th>Immatriculation</th>
                                <th>Odomètre</th>
                                <th>Dernière communication</th>
                                <th>Prise de service</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.vehicles.map(vehicle => {
                                    return (
                                        <tr>
                                            <td>{vehicle.identifier}</td>
                                            <td>{vehicle.serial}</td>
                                            <td>{vehicle.odometer} km</td>
                                            <td>{new Date(vehicle.lastContact).toLocaleString()}</td>
                                            <td>{vehicle.service}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default VehiclesList