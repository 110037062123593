import React, {Component} from "react"
import { Trans } from "react-i18next"
import { useMap, MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import { greenBusIcon, redBusIcon, orangeBusIcon } from "../../../helpers/Markers"
import classes from "../../Dashboard.module.css"
import VehiclesPositionClasses from "./VehiclesPosition.module.css"

function ChangeView({ center, zoom }) {
    useMap().setView(center, zoom);
    return null;
  }

class VehiclesPosition extends Component {

    state = {
        positions: [
            {
                identifier: "801",
                lat: 49.623503,
                lng: -1.622243,
                lastContact: 1614688334000
            },
            {
                identifier: "922",
                lat: 49.642176,
                lng: -1.699081,
                lastContact: 1614677534000
            }
        ],
        selectedBus: undefined
    }


    onSelectBus = (bus) => {
        const selectedBus = this.state.positions.find(it => it.identifier === bus.target.value)
        console.log(selectedBus)
        this.setState({selectedBus: selectedBus})
    }

    render(){
        return (
            <div className={classes.ChartContainer} style={{width: "100%", marginTop: "50px"}}>
                
                <label><Trans>Position des bus</Trans></label>
                
                <div className={classes.DashboardDevicesTitleSeparator}/>

                <div className={classes.DashboardDevicesGroupContent} style={{height:"800px"}}>
                    <select onChange={this.onSelectBus} className={VehiclesPositionClasses.VehiclePositionBusSelector}>
                        <option selected={this.state.selectedBus === undefined}>Selectionnez un bus</option>
                        {this.state.positions.map(position => {
                            return <option value={position.identifier} selected={this.state.selectedBus === position}>Bus N°{position.identifier}</option>
                        })}
                    </select>
                    <MapContainer style={{width: "100%", height: "90%" }} center={this.state.selectedBus !== undefined ? [this.state.selectedBus.lat, this.state.selectedBus.lng] : [49.633731, -1.622137]} zoom={12} scrollWheelZoom={false}>
                        <ChangeView center={this.state.selectedBus !== undefined ? [this.state.selectedBus.lat, this.state.selectedBus.lng] : [49.633731, -1.622137]} zoom={13} /> 
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
                        {
                            this.state.positions.map(position => {
                                const now = Date.now()
                                let icon = greenBusIcon
                                if(now - position.lastContact > 1000 * 60 * 60 * 12){
                                    icon = orangeBusIcon
                                }else if(now - position.lastContact > 1000 * 60 * 60 * 24){
                                    icon = redBusIcon
                                }
                                return (
                                    <Marker icon={icon} position={[position.lat, position.lng]}>
                                        <Popup>
                                            <b>Bus {position.identifier}</b>
                                            <br />
                                            <label>Dernier contact : {new Date(position.lastContact).toLocaleString()}</label>
                                        </Popup>
                                    </Marker>
                                )
                            })
                        }
                        
                    </MapContainer>
                </div>
            </div>
        )
    }
}

export default VehiclesPosition