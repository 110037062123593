import React from "react"
import propTypes from "prop-types"
import classes from "./Tabs.module.css"
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilter} from "@fortawesome/free-solid-svg-icons";
import Interval from "../../requests/elastic_search/Interval";
import {DateRangePicker} from "rsuite";
import {subDays, subHours} from "date-fns";
import {getI18n, Trans} from 'react-i18next'
import i18n from '../../i18n'


const tabs = (props) => {
    let selectedTab = undefined
    if(!(props.selectedTab === undefined && props.tabs.length === 0)){
        selectedTab = props.selectedTab !== undefined ? props.selectedTab.path : props.tabs[0].path
    }

    const tabs =  props.tabs.map(it => {
        return (
            <NavLink key={it.path} to={it.path} className={classes.tabItem} style={{ textDecoration: 'none' }} onClick={() => props.onSelect(it)}>
                <label className={it.path === selectedTab ? classes.selectedTab : classes.tab}>{it.name}</label>
                {it.path === selectedTab ? <div className={classes.tabSelectedLine}/> : undefined}
            </NavLink>
        )
    });

    return (
        <div className={classes.tabContainer} style={props.noPadding ? {left:"200px", width: "calc(100% - 200px)"} : {}}
        >
            {tabs}

            <div className={classes.TabsFiltersContainer}>
                <div className={classes.DashboardDateRangeContainer}>
                    {props.displayOnlyDateShortcuts === undefined || props.displayOnlyDateShortcuts === false ?

                        <DateRangePicker
                            cleanable={false}
                            value={(props.startDatePeriodTimestamp !== undefined && props.endDatePeriodTimestamp !== undefined) ? [new Date(props.startDatePeriodTimestamp), new Date(props.endDatePeriodTimestamp)] : undefined}
                            placeholder={getI18n().t("SelectPeriod")}
                            onChange={props.onPeriodChange}
                            ranges={[
                                {
                                    label: i18n.t("Last24Hours"),
                                    value: [subHours(new Date(), 24), subDays(new Date(), 1)]
                                },
                                {label: i18n.t("Last7Days"), value: [subDays(new Date(), 6), subDays(new Date(), 1)]},
                                {label: i18n.t("LastMonth"), value: [subDays(new Date(), 30), subDays(new Date(), 1)]},
                                {label: i18n.t("LastYear"), value: [subDays(new Date(), 364), subDays(new Date(), 1)]}
                            ]}/>
                            :
                        <select className={classes.DashboardDateShortcutSelect} onChange={(event => {
                            const nowTimestamp = Date.now()
                            const pastDateTimestamp = nowTimestamp - event.target.value
                            const now = new Date(nowTimestamp)
                            const pastDate = new Date(pastDateTimestamp)
                            props.onPeriodChange([pastDate, now], false)
                        })}>
                            <option value={1000*60*60*24}>{i18n.t("Last24Hours")}</option> {/* value is number of milliseconds to substract to now to ge relative date */}
                            <option value={1000*60*60*48}>{i18n.t("Last48Hours")}</option>
                            <option value={1000*60*60*24*7}>{i18n.t("Last7Days")}</option>
                        </select>
                    }
                </div>

                {/* <div className={classes.DashboardIntervalContainer}>
                    <div className={classes.TabsIntervalButtonsContainer}>
                        <button className={props.interval === Interval.HOUR ? classes.TabsIntervalActiveButton : undefined} onClick={() =>  props.onIntervalChanged(Interval.HOUR)}><Trans>PerHour</Trans></button>
                        <button className={props.interval === Interval.DAY ? classes.TabsIntervalActiveButton : undefined} onClick={() =>  props.onIntervalChanged(Interval.DAY)}><Trans>PerDay</Trans></button>
                    </div>
                </div> */}
            </div>

            {/* <div className={classes.FilterIcon}>
                <FontAwesomeIcon icon={faFilter}/>
            </div> */}
        </div>
    )
}

tabs.propTypes = {
    tabs: propTypes.any.isRequired,
    onSelect: propTypes.func,
    selectedTab: propTypes.object,
    onFilterTap: propTypes.func,

    interval: propTypes.oneOf([Interval.HOUR, Interval.DAY, Interval.MINUTE, Interval.MONTH]),

    onIntervalChanged: propTypes.func,
    onPeriodChange: propTypes.func,

    startDatePeriodTimestamp: propTypes.any,
    endDatePeriodTimestamp: propTypes.any,

    displayOnlyDateShortcuts: propTypes.bool
}

export default tabs;
