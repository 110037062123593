import React, {Component} from "react"
import classes from "../Dashboard.module.css"
import '../graphs/custom_graph.less'

import Interval from "../../requests/elastic_search/Interval"

import Tabs from "../../navigation/tabs/Tabs"
import {Redirect, Route, Switch} from "react-router-dom";
import '../../../node_modules/react-grid-layout/css/styles.css';

import "../grid_override.css"
import DashboardSupervision from "./DashboardSupervision"
import DriversList from "./Drivers/DriversList"
import VehiclesList from "./Vehicles/VehiclesList"
import VehiclesPosition from "./Positions/VehiclesPosition"

import {subDays, subHours} from 'date-fns/fp'
import i18n from "../../i18n"

import ModacOverview from "../modac_overview/ModacOverview"

import ReactTooltip from "react-tooltip";
import Tree from "../tree/Tree"
import VehicleDetails from "../eco_driving/VehicleDetails/VehicleDetails"
class DashboardOperations extends Component {

    constructor(props) {
        super(props)

        const now = new Date(Date.now())
        const oneDayAgo = subDays(1, now)
        if(!window.location.pathname.startsWith("/dashboard/operations/supervision")){
            oneDayAgo.setHours(0,0,0,0);
        }

        this.state = {
            isEvolutionOfCountOfPlaybackPerContentTypeBodyLoaded:false,
            interval: Interval.HOUR,

            startDatePeriodTimestamp: oneDayAgo.getTime(),
            endDatePeriodTimestamp: now.getTime(),

            showTree: false,

            tabs: [
                {
                    name: i18n.t("Supervision"),
                    path: "/dashboard/operations/supervision"
                },
                {
                    name: i18n.t("Conducteurs"),
                    path: "/dashboard/operations/drivers"
                },{
                    name: i18n.t("Véhicules"),
                    path: "/dashboard/operations/vehicles"
                },
                {
                    name: i18n.t("Positions"),
                    path: "/dashboard/operations/positions"
                },
                {
                    name: i18n.t("Évènements"),
                    path: "/dashboard/operations/events"
                }],
            selectedTab: undefined,

            selectedArea: undefined,
            selectedPath: undefined,
            selectedNodeId: undefined,

            currentBreakpoint: undefined,

            modacs: []
        };

    }

    async componentDidMount() {
        this.updateCurrentTabIfNeeded()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.updateCurrentTabIfNeeded()

        // If tabs change and previous or new tab is supervision, we handle date filter (not same date filter on supervision and rest of portal)
        // In two cases, default value is "last 24 hours"
        if(prevState.selectedTab !== this.state.selectedTab &&  (this.state.selectedTab === this.state.tabs[0] || prevState.selectedTab === this.state.tabs[0])){
            const now = new Date(Date.now())
            const oneDayAgo = subHours(24, now)
            // For supervision tab, we get real last 24h. on other default is current days and past day until at 00:00
            if(this.state.selectedTab !== this.state.tabs[0]){
                oneDayAgo.setHours(0,0,0,0);
            }

            this.setState({
                startDatePeriodTimestamp: oneDayAgo.getTime(),
                endDatePeriodTimestamp: now.getTime(),
            })
        }
    }

    onFactoriesLoaded = (factories) => {
        this.setState({factories: factories})
    }

    updateCurrentTabIfNeeded = () => {
        let tab;
        switch(window.location.pathname){
            case "/dashboard/operations/supervision": {
                tab = {...this.state.tabs[0]}
                break
            }
            case "/dashboard/operations/drivers": {
                tab = {...this.state.tabs[1]}
                break
            }
            case "/dashboard/operations/vehicles": {
                tab = {...this.state.tabs[2]}
                break
            }
            case "/dashboard/operations/positions": {
                tab = {...this.state.tabs[3]}
                break
            }
            case "/dashboard/operations/events": {
                tab = {...this.state.tabs[4]}
                break
            }
        }
        if(JSON.stringify(this.state.selectedTab) !== JSON.stringify(tab)){
            this.setState({
                selectedTab: tab
            })
        }
    }


    onFilterTap = () => {
        this.setState({areFiltersVisible: !this.state.areFiltersVisible})
    }


    onPeriodChange = (dates, isMidnightHour = true) => {
        if(dates.length === 0){
            this.setState({
                startDatePeriodTimestamp: undefined,
                endDatePeriodTimestamp: undefined
            })
        }else{
            const endDate = dates[1].getTime() + (isMidnightHour ? ((24 * 60 * 60 * 1000) - 1) : 0); // "Returns date at 00h00, so we had seconds to get same date at 23h59
            this.setState({
                interval: (endDate - dates[0]) > (1000 * 60 * 60 * 24 * 2) ? Interval.DAY : Interval.HOUR,
                startDatePeriodTimestamp: dates[0].getTime(),
                endDatePeriodTimestamp: endDate
            })
        }
    }

    onIntervalChanged = (interval) => {
        const currentInterval = this.state.interval
        if(interval !== currentInterval) {
            this.setState({interval: interval})
        }
    }

    onTabChange = (selectedTab) => {
        this.setState({selectedTab: selectedTab})
    }


    onSelectArea = (area, nodeId, nodePath) => {
        this.setState({selectedArea: area, selectedNodeId: nodeId, selectedAreaPath: nodePath})
    }

    onBreakpointChange = (breakpoint) => {
        this.setState({currentBreakpoint: breakpoint})
    }

    onModacFetched = async(modacs) => {
        await this.setState({modacs: modacs})

        // If a modac was selected (modal shown), update this one
        if(this.state.selectedModac !== undefined){
            const copyOfSelectedModac = {...this.state.selectedModac}
            const updatedModac = this.state.modacs.find(modac => modac.pk === copyOfSelectedModac.pk && modac.status === copyOfSelectedModac.status)
            if(updatedModac !== undefined){
                this.setState({selectedModac: updatedModac})
            }
        }
    }


    onSelectModac = (modacNode, nodeId, nodePath) => {
        if(window.location.pathname === "/dashboard/operations/supervision"){
            const modacMatchingWithModacNode = this.state.modacs.find(modac => modac.pk === nodeId && modac.status === modacNode.status)
            this.setState({selectedModac: modacMatchingWithModacNode !== undefined ? modacMatchingWithModacNode : modacNode})
        }else {
            this.onSelectArea(modacNode, nodeId, nodePath)
        }
    }

    onUnselectModac = () => {
        this.setState({selectedModac: undefined})
    }


    render(){
        return (
            <>
                {this.state.selectedModac === undefined ? undefined : <ModacOverview onCloseModacModal={this.onUnselectModac} modac={this.state.selectedModac}/>}

                {this.state.selectedArea && this.state.selectedArea.type === "bus" ? undefined : <Tabs
                    startDatePeriodTimestamp={this.state.startDatePeriodTimestamp}
                    endDatePeriodTimestamp={this.state.endDatePeriodTimestamp}
                    onPeriodChange={this.onPeriodChange}
                    onIntervalChanged={this.onIntervalChanged}
                    interval={this.state.interval}
                    onSelect={this.onTabChange}
                    tabs={this.state.tabs}
                    selectedTab={this.state.selectedTab}
                    onFilterTap={this.onFilterTap}
                    displayOnlyDateShortcuts={window.location.pathname.startsWith("/dashboard/operations/supervision")}/>}

                <div className={classes.DashboardContainer}>

                    <div className={classes.DashboardDevicesTreeContainer}>
                        <Tree autoExpandAllAreas={true} isAreaManagement={false} hideVisibleNodes={true} onFactoriesLoaded={this.onFactoriesLoaded} onModacFetched={this.onModacFetched} onSelectModac={this.onSelectModac} alreadySelectedNodeId={this.state.selectedNodeId} onSelect={this.onSelectArea} />
                    </div>
                            
                    {this.state.selectedArea && this.state.selectedArea.type === "bus" ? <VehicleDetails id={this.state.selectedArea.val} fromList={false} /> :
                        <Switch>
                            <Redirect exact from={"/dashboard/operations/"} to={"/dashboard/operations/supervision"}/>

                            <Route exact path={"/dashboard/operations/supervision"} render={() =>
                                <DashboardSupervision/>
                            }/>

                            <Route exact path={"/dashboard/operations/drivers"} render={() =>
                                <DriversList />
                            }/>

                            <Route exact path={"/dashboard/operations/vehicles"} render={() =>
                                <VehiclesList />
                            }/>

                            <Route exact path={"/dashboard/operations/positions"} render={() =>
                                <VehiclesPosition />
                            }/>

                        </Switch>
                    }
                    <ReactTooltip  effect="solid" />
                </div>
            </>
        )
    }
}

export default DashboardOperations;
