import {getI18n} from "react-i18next";

export const getDataset = (dataParam) => {
    if(dataParam !== undefined && dataParam.data !== undefined && dataParam.data.aggregations !== undefined && dataParam.data.aggregations.date !== undefined && dataParam.data.aggregations.date.buckets !== undefined) {

        const buckets = dataParam.data.aggregations.date.buckets
        const dataset = {
            backgroundColor: "rgba(44,84,255,0.31)",
            borderColor: "rgb(70,137,255)",
            label: `${getI18n().t("FreeRam")} (Bytes)`,
            data: []
        }

        let dates = []
        buckets.forEach(it => {
            if(it.key_as_string !== undefined && it.freemem !== undefined && it.freemem.value !== undefined){
                const date = new Date(it.key_as_string)
                dates.push(date)

                dataset.data.push(
                    {
                        x: date,
                        y: it.freemem.value.toFixed(0)
                    }
                )
            }
        })
console.log(dataset)
        return {
            datasets: [dataset],
            dates: dates
        }
    }
    return {
        error: "invalid input to generate dataset"
    }
}
