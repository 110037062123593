import React, {Component}  from "react"
import { Trans } from "react-i18next"
import classes from "../../Dashboard.module.css"

class WarningPoints extends Component {
    
    state = {
        issues: [
            {
                level: 1,
                vehicleNumber: 801,
                description: "Alimentation (pompe à injection)",
                detectedAt: 1601644179000
            },
            {
                level: 3,
                vehicleNumber: 801,
                description: "Clignotant latéral gauche HS",
                detectedAt: 1601624179000
            }
        ]
    }
    
    render() {
        return (
            <table>
                    <thead>
                    <tr>
                        <th><Trans>Importance</Trans></th>
                        <th><Trans>N° véhicule</Trans></th>
                        <th><Trans>Défaut</Trans></th>
                        <th><Trans>Date de détection</Trans></th>
                    </tr>
                    </thead>
    
                    <tbody>
                    {
                        this.state.issues.map(issue => {
                            return (
                                <tr className={classes.DashboardSupervisionErrorRow} key={"key1"}>
                                <td className={issue.level === 1 ? classes.SaturateAlarmsCountText : issue.level === 2 ? classes.MediumAlarmsCountText : issue.level === 3 ? classes.LowAlarmsCountText : null}>
                                    {issue.level === 1 ? "Forte" : null} {issue.level === 2 ? "Moyenne" : null} {issue.level === 3 ? "Légère" : null}
                                </td>
                                <td>{issue.vehicleNumber}</td>
                                <td>{issue.description}</td>
                                <td>{new Date(issue.detectedAt).toLocaleString()}</td>
                            </tr>
                            )
                        })
                    }
                    </tbody>
                </table>
        )   
    }
}

export default WarningPoints