export const getEvolutionOfEngineOilTemperature = () => {
    const today = new Date(Date.now())
    const day = today.getDate()
    console.log("2020-10-" + day.toString() + "T01:00:00.000+02:00")
    return [
        {
            "key_as_string": "2020-10-" + day.toString() + "T01:00:00.000+02:00",
            "temperature": "20"
        },
        {
            "key_as_string": "2020-10-" + day.toString() + "T01:05:00.000+02:00",
            "temperature": "21"
        },
        {
            "key_as_string": "2020-10-" + day.toString() + "T01:10:00.000+02:00",
            "temperature": "24"
        },
        {
            "key_as_string": "2020-10-" + day.toString() + "T01:15:00.000+02:00",
            "temperature": "28"
        },
        {
            "key_as_string": "2020-10-" + day.toString() + "T01:16:00.000+02:00",
            "temperature": "35"
        },
        {
            "key_as_string": "2020-10-" + day.toString() + "T01:17:00.000+02:00",
            "temperature": "40"
        },
        {
            "key_as_string": "2020-10-" + day.toString() + "T01:17:30.000+02:00",
            "temperature": "50"
        },
        {
            "key_as_string": "2020-10-" + day.toString() + "T01:18:00.000+02:00",
            "temperature": "60"
        },
        {
            "key_as_string": "2020-10-" + day.toString() + "T01:18:30.000+02:00",
            "temperature": "75"
        },
        {
            "key_as_string": "2020-10-" + day.toString() + "T01:19:00.000+02:00",
            "temperature": "82"
        },
        {
            "key_as_string": "2020-10-" + day.toString() + "T01:19:30.000+02:00",
            "temperature": "82"
        },
        {
            "key_as_string": "2020-10-" + day.toString() + "T01:20:00.000+02:00",
            "temperature": "82"
        },
        {
            "key_as_string": "2020-10-" + day.toString() + "T01:20:30.000+02:00",
            "temperature": "83"
        },
        {
            "key_as_string": "2020-10-" + day.toString() + "T01:21:00.000+02:00",
            "temperature": "83"
        },
        {
            "key_as_string": "2020-10-" + day.toString() + "T01:21:30.000+02:00",
            "temperature": "82"
        },





        {
            "key_as_string": "2020-10-" + day.toString() + "T01:22:30.000+02:00",
            "temperature": "82"
        },
        {
            "key_as_string": "2020-10-" + day.toString() + "T01:23:30.000+02:00",
            "temperature": "82"
        },
        {
            "key_as_string": "2020-10-" + day.toString() + "T01:24:30.000+02:00",
            "temperature": "82"
        },
        {
            "key_as_string": "2020-10-" + day.toString() + "T01:25:30.000+02:00",
            "temperature": "82"
        },
        {
            "key_as_string": "2020-10-" + day.toString() + "T01:26:30.000+02:00",
            "temperature": "82"
        },
        {
            "key_as_string": "2020-10-" + day.toString() + "T01:27:30.000+02:00",
            "temperature": "82"
        },
        {
            "key_as_string": "2020-10-" + day.toString() + "T01:28:30.000+02:00",
            "temperature": "82"
        },
        {
            "key_as_string": "2020-10-" + day.toString() + "T01:29:30.000+02:00",
            "temperature": "82"
        },
        {
            "key_as_string": "2020-10-" + day.toString() + "T01:30:30.000+02:00",
            "temperature": "82"
        },
        {
            "key_as_string": "2020-10-" + day.toString() + "T01:31:30.000+02:00",
            "temperature": "82"
        },
        {
            "key_as_string": "2020-10-" + day.toString() + "T01:41:30.000+02:00",
            "temperature": "82"
        },
        {
            "key_as_string": "2020-10-" + day.toString() + "T01:51:30.000+02:00",
            "temperature": "82"
        },
        {
            "key_as_string": "2020-10-" + day.toString() + "T02:01:30.000+02:00",
            "temperature": "82"
        },
        {
            "key_as_string": "2020-10-" + day.toString() + "T02:11:30.000+02:00",
            "temperature": "82"
        },
        {
            "key_as_string": "2020-10-" + day.toString() + "T03:21:30.000+02:00",
            "temperature": "82"
        },
        {
            "key_as_string": "2020-10-" + day.toString() + "T04:21:30.000+02:00",
            "temperature": "82"
        },
        {
            "key_as_string": "2020-10-" + day.toString() + "T05:21:30.000+02:00",
            "temperature": "75"
        },
        {
            "key_as_string": "2020-10-" + day.toString() + "T05:23:30.000+02:00",
            "temperature": "70"
        },
        {
            "key_as_string": "2020-10-" + day.toString() + "T05:30:30.000+02:00",
            "temperature": "55"
        },
        {
            "key_as_string": "2020-10-" + day.toString() + "T05:50:30.000+02:00",
            "temperature": "40"
        },
        {
            "key_as_string": "2020-10-" + day.toString() + "T06:21:30.000+02:00",
            "temperature": "26"
        },
        {
            "key_as_string": "2020-10-" + day.toString() + "T06:30:30.000+02:00",
            "temperature": "22"
        },
        {
            "key_as_string": "2020-10-" + day.toString() + "T06:48:30.000+02:00",
            "temperature": "17"
        },
        {
            "key_as_string": "2020-10-" + day.toString() + "T06:58:30.000+02:00",
            "temperature": "15"
        },



        {
            "key_as_string": "2020-10-" + day.toString() + "T07:58:30.000+02:00",
            "temperature": "15"
        },
        {
            "key_as_string": "2020-10-" + day.toString() + "T08:58:30.000+02:00",
            "temperature": "16"
        },
        {
            "key_as_string": "2020-10-" + day.toString() + "T09:58:30.000+02:00",
            "temperature": "17"
        },



        {
            "key_as_string": "2020-10-" + day.toString() + "T10:50:50.000+02:00",
            "temperature": "18"
        },
        {
            "key_as_string": "2020-10-" + day.toString() + "T10:58:59.000+02:00",
            "temperature": "30"
        },
        {
            "key_as_string": "2020-10-" + day.toString() + "T11:00:00.000+02:00",
            "temperature": "40"
        },
        {
            "key_as_string": "2020-10-" + day.toString() + "T11:01:00.000+02:00",
            "temperature": "52"
        },
        {
            "key_as_string": "2020-10-" + day.toString() + "T11:02:30.000+02:00",
            "temperature": "61"
        },
        {
            "key_as_string": "2020-10-" + day.toString() + "T11:03:00.000+02:00",
            "temperature": "71"
        },
        {
            "key_as_string": "2020-10-" + day.toString() + "T11:04:00.000+02:00",
            "temperature": "82"
        },
        {
            "key_as_string": "2020-10-" + day.toString() + "T11:05:00.000+02:00",
            "temperature": "82"
        },
        {
            "key_as_string": "2020-10-" + day.toString() + "T11:10:00.000+02:00",
            "temperature": "82"
        },
        {
            "key_as_string": "2020-10-" + day.toString() + "T11:14:00.000+02:00",
            "temperature": "82"
        },
        {
            "key_as_string": "2020-10-" + day.toString() + "T11:30:00.000+02:00",
            "temperature": "83"
        },


        {
            "key_as_string": "2020-10-" + day.toString() + "T12:30:00.000+02:00",
            "temperature": "83"
        },
        {
            "key_as_string": "2020-10-" + day.toString() + "T13:30:00.000+02:00",
            "temperature": "83"
        },
        {
            "key_as_string": "2020-10-" + day.toString() + "T14:30:00.000+02:00",
            "temperature": "83"
        },
        {
            "key_as_string": "2020-10-" + day.toString() + "T15:30:00.000+02:00",
            "temperature": "83"
        },
        {
            "key_as_string": "2020-10-" + day.toString() + "T15:40:00.000+02:00",
            "temperature": "83"
        },
    ]
}